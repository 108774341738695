import PropTypes from "prop-types";

export const TableBodyPropTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  enableSelect: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  onSelectRow: PropTypes.func,
};
