import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TablePagination,
  Grid,
} from "@material-ui/core";

import { JSUTableHead, JSUTableBody } from "./components";
import { JSUTableDefaultProps, JSUTablePropTypes } from "./JSUTable.types";
import useStyles from "./JSUTable.jss";

/**
 * Table Component
 *
 * The table for data display
 */
const JSUTable = (props) => {
  const {
    columns,
    rows,
    tableProps,
    enableSelect,
    selectedIds,
    onSelect,
    selectedData,
    onSelectedData,
    enablePagination,
    totalCount,
    page,
    limit,
    pageLimitList,
    onPageChange,
    onLimitChange,
    onRequestSort,
    orderableCols,
    order,
    orderBy,
    actionButtons,
    actionColLabel,
  } = props;
  const classes = useStyles();

  const onSelectAllClick = () => {
    const ids = rows.map((row) => row.id);
    if (ids.length === selectedIds.length) {
      onSelect([]);
      onSelectedData([]);
    } else {
      onSelect(ids);
      onSelectedData(rows);
    }
  };

  const onSelectRow = (id, data) => {
    if (selectedIds.includes(id)) {
      let filteredID = selectedIds.filter((selectedId) => selectedId !== id);
      onSelect(filteredID);
      let filteredData = [];
      selectedData.map((item) => {
        if (filteredID.includes(item.id)) {
          filteredData.push(item);
        }
      });
      onSelectedData(filteredData);
    } else {
      onSelect([...selectedIds, id]);
      onSelectedData([...selectedData, data]);
    }
  };

  const handleChangePage = (e, newPage) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    onLimitChange(parseInt(e.target.value, 10));
    onPageChange(0);
  };

  return (
    <Paper className={classes.paper}>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader {...tableProps}>
          <JSUTableHead
            columns={columns}
            enableSelect={enableSelect}
            onRequestSort={onRequestSort}
            onSelectAllClick={onSelectAllClick}
            orderableCols={orderableCols}
            order={order}
            orderBy={orderBy}
            numSelected={selectedIds.length}
            rowCount={rows.length}
            actionButtons={actionButtons}
            actionColLabel={actionColLabel}
          />
          <JSUTableBody
            columns={columns}
            rows={rows}
            enableSelect={enableSelect}
            selectedIds={selectedIds}
            order={order}
            orderBy={orderBy}
            onSelectRow={onSelectRow}
            actionButtons={actionButtons}
          />
        </Table>
      </TableContainer>
      {enablePagination && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <TablePagination
            rowsPerPageOptions={pageLimitList}
            component="div"
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            labelRowsPerPage="Page Size:"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      )}
    </Paper>
  );
};

JSUTable.prototype = JSUTablePropTypes;
JSUTable.defaultProps = JSUTableDefaultProps;

export default JSUTable;
