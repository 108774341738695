import React, { createContext, useContext, useState } from "react";
import {
  parseSummary,
  readGraphical,
  parseGraphical,
} from "~utils/templates/General";
import {
  parseActivityLevel,
  parseActivityTabular,
  readActivityTabular,
  readActivitySummary,
  readActivityLevel,
} from "~utils/templates/Activity";
import {
  parseNodeData,
  getActivityListFromCategory,
} from "~utils/templates/Category";

import {
  createActivityTemplate,
  getActivityTemplate,
  updateActivityTemplate,
  getAllActivity,
  deleteActivityTemplate,
  getAllCategory,
} from "~apis/template";
import { AuthContext } from "..";

export const CompanyActivityTemplateContext = createContext({});

export const CompanyActivityTemplateProvider = (props) => {
  const { signedInCompany } = useContext(AuthContext);

  const [templateName, setTemplateName] = useState("");
  const [activity, setActivity] = useState([
    {
      datetimeType: "datetime",
      fieldIndex: 0,
      fieldName: "Activity Date/Time",
      fieldType: "datetime",
      fieldUID: "activityDatetime",
      required: true,
    },
  ]);
  const [problem, setProblem] = useState([
    {
      datetimeType: "datetime",
      fieldIndex: 0,
      fieldName: "Problem Date/Time",
      fieldType: "datetime",
      fieldUID: "activityDatetime",
      required: true,
    },
  ]);

  const [activityOutputTabular, setActivityOutputTabular] = useState([]);
  const [activityOutputGraphical, setActivityOutputGraphical] = useState([]);
  const [activityOutputSummary, setActivityOutputSummary] = useState([]);
  const [problemOutputTabular, setProblemOutputTabular] = useState([]);
  const [problemOutputGraphical, setProblemOutputGraphical] = useState([]);
  const [problemOutputSummary, setProblemOutputSummary] = useState([]);

  const [createdDateTime, setCreatedDateTime] = useState("");
  const [lastModified, setLastModified] = useState("");

  const [templateUID, setTemplateUID] = useState("");
  // const [templateList, setTemplateList] = useState([]);

  const [isValid, setIsValid] = useState({
    templateName: false,
    tableNames: [],
  });

  //For table
  const [pagedList, setPagedList] = useState([]);
  const [totalCount, setTotalCount] = useState();

  //For review
  const [fromProjectActivity, setFromProjectActivity] = useState(false);

  const parseTemplateData = (isEdit) => {
    const data = {
      name: templateName,
      createdDateTime: isEdit ? createdDateTime : new Date().toISOString(),
      lastModified: new Date().toISOString(),
      activity: parseActivityLevel(activity),
      problem: parseActivityLevel(problem),
      output: {
        activityOutput: {
          tabular: parseActivityTabular(activityOutputTabular),
          graphical: parseGraphical(activityOutputGraphical),
          summary: parseSummary(activityOutputSummary),
        },
        problemOutput: {
          tabular: parseActivityTabular(problemOutputTabular),
          graphical: parseGraphical(problemOutputGraphical),
          summary: parseSummary(problemOutputSummary),
        },
      },
    };
    return data;
  };

  const readTemplateData = (data, uid) => {
    setTemplateUID(uid);
    setTemplateName(data.name);
    setIsValid({
      templateName: true,
      tableNames: [],
    });
    setActivity(readActivityLevel(data.activity));
    setProblem(readActivityLevel(data.problem));
    setActivityOutputTabular(
      readActivityTabular(data.output.activityOutput.tabular),
    );
    setActivityOutputGraphical(
      readGraphical(data.output.activityOutput.graphical),
    );
    setActivityOutputSummary(
      readActivitySummary(data.output.activityOutput.summary),
    );

    setProblemOutputTabular(
      readActivityTabular(data.output.problemOutput.tabular),
    );
    setProblemOutputGraphical(
      readGraphical(data.output.problemOutput.graphical),
    );
    setProblemOutputSummary(
      readActivitySummary(data.output.problemOutput.summary),
    );

    setCreatedDateTime(data.createdDateTime);
    setLastModified(data.lastModified);
  };

  const saveActivityTemplateHandler = async () => {
    const data = parseTemplateData(false);
    await createActivityTemplate(signedInCompany.id, data);
  };

  const editActivityTemplateHandler = async () => {
    const data = parseTemplateData(false);
    await updateActivityTemplate(signedInCompany.id, templateUID, data);
  };

  const getAllActivityTemplateHandler = async (page, limit) => {
    getAllActivity(signedInCompany.id).then((data) => {
      const offset = page * limit;
      const mockPagination = data.slice(offset, offset + limit);
      setPagedList(mockPagination);
      setTotalCount(data.length);
    });
  };

  const deleteActivityTemplateHandler = async (id) => {
    await deleteActivityTemplate(signedInCompany.id, id);
    await getAllActivityTemplateHandler(0, 100);
  };

  const getActivityTemplateHandler = async (id) => {
    getActivityTemplate(signedInCompany.id, id).then((data) => {
      if (data) {
        readTemplateData(data, data.id);
      }
    });
  };

  const checkUsed = async (id) => {
    const categories = await getAllCategory(signedInCompany.id);
    let activityList = [];
    categories.map((item) => {
      let tmpdata = JSON.parse(JSON.stringify(item.categoryData));
      parseNodeData(tmpdata, 0, null);
      activityList.push({
        categoryName: item.templateName,
        activities: getActivityListFromCategory(tmpdata),
      });
    });
    const usedCategories = [];
    activityList.forEach((item) => {
      item.activities.forEach((activity) => {
        if (activity.id === id) {
          usedCategories.push(item.categoryName);
        }
      });
    });
    return usedCategories;
  };

  return (
    <CompanyActivityTemplateContext.Provider
      value={{
        templateName,
        setTemplateName,
        activity,
        setActivity,
        problem,
        setProblem,
        activityOutputTabular,
        setActivityOutputTabular,
        activityOutputGraphical,
        setActivityOutputGraphical,
        activityOutputSummary,
        setActivityOutputSummary,
        problemOutputTabular,
        setProblemOutputTabular,
        problemOutputGraphical,
        setProblemOutputGraphical,
        problemOutputSummary,
        setProblemOutputSummary,
        createdDateTime,
        setCreatedDateTime,
        lastModified,
        setLastModified,

        templateUID,
        setTemplateUID,
        isValid,
        setIsValid,

        parseTemplateData,
        readTemplateData,
        saveActivityTemplateHandler,
        editActivityTemplateHandler,
        getActivityTemplateHandler,
        getAllActivityTemplateHandler,
        deleteActivityTemplateHandler,
        checkUsed,

        //For table
        pagedList,
        setPagedList,
        totalCount,
        setTotalCount,

        //For Review
        fromProjectActivity,
        setFromProjectActivity,
      }}
    >
      {props.children}
    </CompanyActivityTemplateContext.Provider>
  );
};
