import { get, post, put } from "~utils/httpClient";
import config from "~config";

const { serverHost } = config;

/**
 * Return user info
 *
 * @param {string} id User ID
 * @return {object} User object
 */
export const getUserById = async (id) => {
  const { ok, data } = await get(`${serverHost}/api/v0/User/${id}`);
  return ok ? data : null;
};

// TODO: implement paginiation
/**
 * Return user list
 *
 * @param {number} offset query offset for paginiation
 * @param {number} limit query limit for paginiation
 * @param {string} order asc/desc order
 * @param {string} orderBy order by certain field, e.g. ID
 * @return {object[]} user list
 */
export const getUserList = async () => {
  const { ok, data } = await get(`${serverHost}/api/v0/User`);
  return ok ? data : [];
};

/**
 * Create new user
 * @param {object} userData user information
 * @return {object} created doc
 */
export const createUser = async (userData) => {
  const { ok, data } = await post(`${serverHost}/api/v0/User`, {
    data: {
      data: userData,
    },
  });
  return ok ? data : null;
};

/**
 * Update user
 * @param {string} id user id
 * @param {object} userData user information
 * @return {object} updated doc
 */
export const updateUser = async (id, userData) => {
  const { ok, data } = await put(`${serverHost}/api/v0/User/${id}`, {
    data: {
      data: userData,
    },
  });
  return ok ? data : null;
};

/**
 * Search users by name
 * @param {string} name
 * @return {object[]}
 */
export const searchUserByName = async (name) => {
  const { ok, data } = await post(`${serverHost}/api/v0/User/Search`, {
    data: { query: name },
  });
  return ok ? data : [];
};

/**
 * Search users
 * @param {object} filter
 * @return {object[]}
 */
export const searchUsers = async (filter) => {
  const { ok, data } = await post(`${serverHost}/api/v0/User/Search`, {
    data: filter,
  });
  return ok ? data : [];
};

/**
 * Update user
 * @param {string} id user id
 * @param {object} userData user information
 * @return {object} updated doc
 */
export const uploadUserPhoto = async (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  const { ok, data } = await put(`${serverHost}/api/v0/User/${id}`, {
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  });
  return ok ? data : null;
};
