// TODO: need optimization, hard to understand
import React, { useState, useEffect } from "react";

import {
  NumberField,
  DropdownField,
  DatetimeField,
  TextField,
} from "./components";

/**
 * DynamicForm used for project info input
 */
const DynamicForm = (props) => {
  const [fields, setFields] = useState([]);
  const [validator, setValidator] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    setFields(props.fields);
    setValidator(Array(Object.keys(props.fields).length).fill(false));
    Object.keys(props.fields).forEach((e, i) => {
      const field = props.fields[e];
      setDataHandler(i, {
        id: field.fieldUID,
        value: "",
        type: field.fieldType,
      });
    });
  }, [props.fields]);

  const Fields = (props) => {
    const field = props.field;
    const fieldType = field.fieldType;
    switch (fieldType) {
      case "number":
        return (
          <NumberField
            {...field}
            validator={props.validator}
            setData={props.setData}
          />
        );
      case "smallText":
        return (
          <TextField
            {...field}
            validator={props.validator}
            setData={props.setData}
          />
        );
      case "longText":
        return (
          <TextField
            {...field}
            validator={props.validator}
            setData={props.setData}
          />
        );
      case "dropdown":
        return (
          <DropdownField
            {...field}
            validator={props.validator}
            setData={props.setData}
          />
        );
      case "datetime":
        return (
          <DatetimeField
            {...field}
            validator={props.validator}
            setData={props.setData}
          ></DatetimeField>
        );
      default:
        return <>{fieldType}</>;
    }
  };

  const validatehandler = (index, isValid) => {
    let arr = validator;
    arr[index] = isValid;
    setValidator(arr);
  };

  const setDataHandler = (index, receivedData) => {
    let arr = data;
    arr[index] = receivedData;
    setData(arr);
    props.setProjectInfo(arr);
  };

  return (
    <>
      {Object.keys(fields).map((e, i) => (
        <Fields
          key={`${e}_${i}`}
          field={fields[e]}
          validator={[validatehandler, i]}
          setData={[setDataHandler, i]}
        />
      ))}
    </>
  );
};

export default DynamicForm;
