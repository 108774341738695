import * as React from "react";

import { CompanyChatRoutes } from "./CompanyChat";
import { DashboardRoutes } from "./Dashboard";
import { ProjectChatRoutes } from "./ProjectChat";

const ChatIndex = React.lazy(() => import("./Chat"));
const routePrefix = "/chat";

// all routes for sub pages
export const chatPageRoutes = [
  ...DashboardRoutes,
  ...ProjectChatRoutes,
  ...CompanyChatRoutes,
];

// default redirect to this path
export const homePagePath = `${routePrefix}/dashboard`;

// used for sidebar rendering by defined navigation
export const chatSidebarNavs = chatPageRoutes.filter(
  (route) => !!route.navigation,
);

// used for Router rendering
export const ChatRoutes = [
  {
    id: "CHAT_INDEX",
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: ChatIndex,
  },
];
