import * as React from "react";

const CategoryFormPage = React.lazy(() => import("./CategoryFormPage"));

const CategoryListPage = React.lazy(() => import("./CategoryListPage"));

const routePrefix = "/company/template/category";

export const CompanyCategoryRoutes = [
  {
    id: "CATEGORY_LIST",
    exact: true,
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: CategoryListPage,
    navigation: {
      section: "company",
      navGroup: "Template",
      navTitle: "Category",
      permission: "company-template",
    },
  },
  {
    id: "CATEGORY_FORM_CREATE",
    path: `${routePrefix}/create`,
    layoutId: "MainLayout",
    component: CategoryFormPage,
  },
  {
    id: "CATEGORY_FORM_UPDATE",
    path: `${routePrefix}/:id`,
    layoutId: "MainLayout",
    component: CategoryFormPage,
  },
];
