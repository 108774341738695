import React, { useContext } from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavTitle,
  CSidebarNavItem,
} from "@coreui/react";
import { Badge } from "@material-ui/core";
import _ from "lodash";

import logo from "src/assets/img/logo-rectangle-white.png";
import { AppContext, AuthContext, NotificationContext } from "~contexts";
import useStyles from "./Sidebar.jss";
import {
  adminSidebarNavs,
  companySidebarNavs,
  projectSidebarNavs,
  chatSidebarNavs,
} from "~pages";

const chatNavMaps = {
  "Project Chat": "project",
  "Company Chat": "company",
};

const Sidebar = (props) => {
  const { showSidebar } = props;
  const { currentPageSection } = useContext(AppContext);
  const { signedInCompany } = useContext(AuthContext);
  const { unreadMsgCount } = useContext(NotificationContext);
  const classes = useStyles();

  const renderSidebarContent = (navGroups, routes) => {
    return (
      <>
        {navGroups.map((navGroup) => {
          const authedRoutes = routes.filter(
            (route) => route.navigation.navGroup === navGroup,
          );
          if (authedRoutes.length > 0) {
            return (
              <div key={`nav-admin-group-${navGroup}`}>
                <CSidebarNavTitle>{navGroup}</CSidebarNavTitle>
                {authedRoutes.map((route) => {
                  return (
                    <CSidebarNavItem
                      key={`nav-${route.id}`}
                      name={route.navigation.navTitle}
                      to={route.path}
                    />
                  );
                })}
              </div>
            );
          }
        })}
      </>
    );
  };

  const SidebarItemProject = () => {
    const navGroups = ["Project", "Management"];
    const adminRoutes = projectSidebarNavs.filter(
      (route) => route.navigation && route.navigation.section === "project",
    );
    return renderSidebarContent(navGroups, adminRoutes);
  };

  const SidebarItemCompany = () => {
    const navGroups = ["Project", "Template", "Administration"];
    const companyRoutes = companySidebarNavs.filter((route) => {
      if (route.navigation && route.navigation.section === "company") {
        if (!route.navigation.permission) return true;
        const ownedPermissions = _.get(
          signedInCompany,
          "companyAuth.permissions.company",
          [],
        );
        return (
          route.navigation.permission &&
          ownedPermissions.includes(route.navigation.permission)
        );
      }
      return false;
    });
    return renderSidebarContent(navGroups, companyRoutes);
  };

  const SidebarItemAdmin = () => {
    const navGroups = ["JSU Management"];
    const adminRoutes = adminSidebarNavs.filter(
      (route) => route.navigation && route.navigation.section === "admin",
    );
    return renderSidebarContent(navGroups, adminRoutes);
  };

  const SidebarItemChat = () => {
    // const navGroups = ["Chat"];
    const navGroup = "Chat";
    const chatRoutes = chatSidebarNavs.filter(
      (route) => route.navigation && route.navigation.section === "chat",
    );
    return (
      <div key={`nav-admin-group-${navGroup}`}>
        <CSidebarNavTitle>{navGroup}</CSidebarNavTitle>
        {chatRoutes.map((route) => {
          return unreadMsgCount[chatNavMaps[route.navigation.navTitle]] &&
            unreadMsgCount[chatNavMaps[route.navigation.navTitle]] > 0 ? (
            <Badge
              key={`nav-${route.id}`}
              style={{ width: "100%" }}
              anchorOrigin={{
                vertical: "0",
                horizontal: "left",
              }}
              color="secondary"
              badgeContent={
                unreadMsgCount[chatNavMaps[route.navigation.navTitle]] || "0"
              }
              max={99}
            >
              <CSidebarNavItem
                name={route.navigation.navTitle}
                to={route.path}
              />
            </Badge>
          ) : (
            <CSidebarNavItem
              key={`nav-${route.id}`}
              name={route.navigation.navTitle}
              to={route.path}
            />
          );
        })}
      </div>
    );
  };

  return (
    <CSidebar show={showSidebar}>
      <CSidebarBrand className="d-md-down-none" style={{ height: "60px" }}>
        <img src={logo} alt="logo" />
      </CSidebarBrand>

      <CSidebarNav>
        {currentPageSection === "company" && !!signedInCompany && (
          <SidebarItemCompany />
        )}
        {currentPageSection === "admin" && <SidebarItemAdmin />}
        {currentPageSection === "project" && !!signedInCompany && (
          <SidebarItemProject />
        )}
        {currentPageSection === "chat" && <SidebarItemChat />}
      </CSidebarNav>
      <div className={classes.copyRightFooter}>
        &copy; 2021-{new Date().getFullYear()} Job Site Update
      </div>
    </CSidebar>
  );
};

export default Sidebar;
