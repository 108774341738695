import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { JSUDialogPropTypes, JSUDialogDefaultProps } from "./JSUDialog.types";
import useStyles from "./JSUDialog.jss";

/**
 * Popup window with action button
 */
const JSUDialog = (props) => {
  const { open, onClose, onSave, width, title, content, buttons } = props;
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth={width}
      fullWidth={true}
    >
      <DialogTitle className={classes.title}>
        <Grid container justifyContent="space-between">
          <div className={classes.titleText}>{title}</div>
          <div className={classes.titleBtnArea}>
            {buttons}
            {onSave && (
              <IconButton
                disableRipple
                className={classes.saveBtn}
                onClick={onSave}
                size="small"
              >
                <SaveIcon />
              </IconButton>
            )}
            {onClose && (
              <IconButton
                disableRipple
                className={classes.closeBtn}
                onClick={onClose}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>{content}</DialogContent>
    </Dialog>
  );
};

JSUDialog.prototype = JSUDialogPropTypes;
JSUDialog.defaultProps = JSUDialogDefaultProps;

export default JSUDialog;
