import React from "react";
import {
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";

import { UnitSelectComponentPropTypes } from "./UnitSelectComponent.types";

const UnitSelectComponent = (props) => {
  const { unitType } = props;

  // TODO: Need to thorougly check
  const dropdownUnitSelectHandler = (unitValue) => {
    props.setUnitHandler(unitValue.unitUID);
  };

  const UnitNone = () => {
    return <></>;
  };

  const UnitFixed = (props) => {
    // TODO: Change Styling later
    const unitValue = props.unitValue;
    return (
      <>
        <p>{unitValue}</p>
      </>
    );
  };

  // TODO: Need Thorough testing
  const UnitDropDown = (props) => {
    const unitValue = props.unitValue;

    return (
      <CDropdown>
        <CDropdownToggle color="secondary">Dropdown button</CDropdownToggle>
        <CDropdownMenu>
          {Object.keys(unitValue).map((e) => (
            <CDropdownItem
              key={e.unitUID}
              onclick={() => {
                dropdownUnitSelectHandler(e);
              }}
            >
              {e.unitName}
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
    );
  };

  const Unit = (props) => {
    if (unitType === "none") return <UnitNone {...props}></UnitNone>;
    else if (unitType === "fixed") return <UnitFixed {...props}></UnitFixed>;
    else if (unitType === "dropdown")
      return <UnitDropDown {...props}></UnitDropDown>;
    else {
      console.error(unitType, props);
      throw Error("Invalid Unit Type");
    }
  };

  return <Unit {...props} />;
};

UnitSelectComponent.propTypes = UnitSelectComponentPropTypes;

export default UnitSelectComponent;
