//Job Template helper functions

/**
 * parse the job table data to back-end format
 * @param {*} table
 * @returns
 */
export function parseJobTabular(table) {
  const tmp_table = table;

  for (let i = 0; i < tmp_table.length; i++) {
    delete tmp_table[i].id;
  }
  return tmp_table;
}

/**
 * read the job table data and parse to front-end format
 * @param {*} table
 * @returns
 */
export function readJobTabular(table) {
  if (table) {
    let tmp_table = table.map((titem, key) => {
      if (titem.tableColumns) {
        let tmpTableColumns = titem.tableColumns.map((item, key) => {
          let tmp_summaryFieldValues = [];
          if (item.columnType === "categorySummaryField") {
            Object.keys(item.summaryFieldValues).forEach(function (key) {
              tmp_summaryFieldValues.push({
                ...item.summaryFieldValues[key],
                summaryCategoryID: key,
              });
            });
          }

          return {
            id: key,
            columnName: item.columnName,
            columnID: item.columnID,
            columnType: item.columnType,
            summaryFieldValues: tmp_summaryFieldValues,
          };
        });

        return {
          ...titem,
          id: key,
          tableColumns: tmpTableColumns,
        };
      }
    });
    return tmp_table;
  }
}

/**
 * read the job graph data and parse to front-end format
 * @param {*} data
 * @returns
 */
export function readJobGraph(data) {
  return Object.keys(data).map((key) => {
    return {
      ...data[key],
    };
  });
}

/**
 * parse Job summary to front-end format
 * @param {*} summary
 * @returns
 */
export function readJobSummary(summary) {
  let tmp = [];
  Object.keys(summary).forEach(function (key) {
    tmp.push(summary[key]);
  });
  return tmp;
}

//Not used
/**
 * use projectCategoryData and projectJobData to check if
 * there is conflict in output
 * @param {Array} categoryList
 * @param {Array} job
 * @returns
 */
export function checkJob(categoryList, job) {
  const categorySummaryList = categoryList.map((item) => {
    return {
      name: item.templateName,
      summary: item.output.summary,
    };
  });

  let res = [];
  job.map((jitem) => {
    jitem.output.tabular.map((titem) => {
      titem.tableColumns.map((column) => {
        if (column.columnType === "categorySummaryField") {
          Object.keys(column.summaryFieldValues).forEach(function (key) {
            const name = key;
            const value = column.summaryFieldValues[key].summaryFieldID;
            let valid = false;
            for (let i = 0; i < categorySummaryList.length; i++) {
              if (categorySummaryList[i].name === name) {
                if (categorySummaryList[i].summary[value]) {
                  valid = true;
                  break;
                }
              }
            }
            if (!valid) {
              res.push({ name: jitem.jobName, column: column.columnName });
            }
          });
        }
      });
    });
  });
  return res;
}
