import React, { createContext, useState } from "react";
import { useContext } from "react";
import {
  createProjectTemplate,
  updateProjectTemplate,
  getAllProjectTemp,
  deleteProjectTemplate,
  getProjectTemplate,
} from "~apis/template";
import { AuthContext } from "..";

export const CompanyProjectTemplateContext = createContext({});

export const CompanyProjectTemplateProvider = (props) => {
  const { signedInCompany } = useContext(AuthContext);

  const [templateName, setTemplateName] = useState("");
  const [projectFields, setProjectFields] = useState([]);

  const [createdDateTime, setCreatedDateTime] = useState("");
  const [lastModified, setLastModified] = useState("");

  const [templateUID, setTemplateUID] = useState("");
  const [isValid, setIsValid] = useState({
    templateName: false,
  });

  //For table
  const [pagedList, setPagedList] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const saveProjectTemplateHandler = async () => {
    let tmp_fields = {};
    projectFields.map((item) => {
      tmp_fields = {
        ...tmp_fields,
        [item.fieldUID]: item,
      };
    });
    let data = {
      templateName: templateName,
      projectFields: tmp_fields,
      createdDateTime: new Date(),
      lastModified: new Date(),
    };

    await createProjectTemplate(signedInCompany.id, data);
  };

  const editProjectTemplateHandler = async () => {
    let tmp_fields = {};
    projectFields.map((item) => {
      tmp_fields = {
        ...tmp_fields,
        [item.fieldUID]: item,
      };
    });
    let data = {
      templateName: templateName,
      projectFields: tmp_fields,
      createdDateTime: new Date(),
      lastModified: new Date(),
    };

    await updateProjectTemplate(signedInCompany.id, templateUID, data);
  };

  const getAllProjectTemplateHandler = (page, limit) => {
    getAllProjectTemp(signedInCompany.id).then((data) => {
      const offset = page * limit;
      const mockPagination = data.slice(offset, offset + limit);
      setPagedList(mockPagination);
      setTotalCount(data.length);
    });
  };

  const deleteProjectTemplateHandler = (id, page, limit) => {
    deleteProjectTemplate(signedInCompany.id, id).then(() => {
      getAllProjectTemplateHandler(page, limit);
    });
  };

  const getProjectTemplateHandler = async (id) => {
    getProjectTemplate(signedInCompany.id, id).then((data) => {
      let tmp_fields = [];
      //object to array
      Object.keys(data.projectFields).forEach(function (key) {
        tmp_fields.push(data.projectFields[key]);
      });
      setTemplateUID(data.id);
      setTemplateName(data.templateName);
      setProjectFields(tmp_fields);
      setCreatedDateTime(data.createdDateTime);
      setLastModified(data.lastModified);
      setIsValid({
        templateName: true,
      });
    });
  };

  return (
    <CompanyProjectTemplateContext.Provider
      value={{
        templateName,
        setTemplateName,
        projectFields,
        setProjectFields,
        createdDateTime,
        setCreatedDateTime,
        lastModified,
        setLastModified,

        templateUID,
        setTemplateUID,
        isValid,
        setIsValid,

        saveProjectTemplateHandler,
        editProjectTemplateHandler,
        getAllProjectTemplateHandler,
        deleteProjectTemplateHandler,
        getProjectTemplateHandler,

        //For table
        pagedList,
        setPagedList,
        totalCount,
        setTotalCount,
      }}
    >
      {props.children}
    </CompanyProjectTemplateContext.Provider>
  );
};
