import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: "1em",
  },
}));

export default useStyles;
