import * as React from "react";

const LoginPage = React.lazy(() => import("./Login"));

const routePrefix = "/login";

export const LoginRoutes = [
  {
    id: "LOGIN_PAGE",
    path: `${routePrefix}`,
    component: LoginPage,
  },
];
