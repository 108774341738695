import React from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import { MainLayout } from "~layouts";
import { AuthContextProvider, NotificationContextProvider } from "~contexts";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { publicRoutes, privateRoutes } from "./routes";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Router = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          {publicRoutes.map((route) => {
            const { component: Component } = route;
            return (
              <PublicRoute
                key={route.id}
                id={route.id}
                exact={route.exact}
                path={route.path}
                render={(props) => <Component {...props} />}
              />
            );
          })}
          <AuthContextProvider>
            <NotificationContextProvider>
              <Switch>
                {privateRoutes
                  .filter((route) => route.layoutId !== "MainLayout")
                  .map((route) => {
                    const { component: Component } = route;
                    return (
                      <PublicRoute
                        key={route.id}
                        id={route.id}
                        exact={route.exact}
                        path={route.path}
                        render={(props) => <Component {...props} />}
                      />
                    );
                  })}
                <Route>
                  <MainLayout>
                    <Switch>
                      {privateRoutes
                        .filter((route) => route.layoutId === "MainLayout")
                        .map((route) => {
                          const { component: Component } = route;
                          return route.isRedirect ? (
                            <Route
                              key={route.id}
                              id={route.id}
                              exact={route.exact}
                              path={route.path}
                            >
                              <Redirect to={{ pathname: route.redirectTo }} />
                            </Route>
                          ) : (
                            <PrivateRoute
                              key={route.id}
                              id={route.id}
                              exact={route.exact}
                              path={route.path}
                              isAuthed={true}
                              render={(props) => <Component {...props} />}
                            />
                          );
                        })}
                    </Switch>
                  </MainLayout>
                </Route>
              </Switch>
            </NotificationContextProvider>
          </AuthContextProvider>
          {/* default page if not found */}
          <Redirect to={{ pathname: "/error/404" }} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default Router;
