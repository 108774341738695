import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  title: {
    borderBottom: "2px solid #ccc",
  },
  content: {
    marginBottom: 20,
    maxHeight: 500,
  },
  titleText: {
    textTransform: "none",
  },
  titleBtnArea: {
    display: "flex",
    gap: 10,
  },
  saveBtn: {
    color: "#39f !important",
  },
  closeBtn: {
    color: "#ced2d8 !important",
  },
});

export default useStyles;
