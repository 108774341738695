import * as React from "react";

const ActivityFormPage = React.lazy(() => import("./ActivityFormPage"));

const ActivityListPage = React.lazy(() => import("./ActivityListPage"));

const routePrefix = "/company/template/activity";

export const CompanyActivityRoutes = [
  {
    id: "ACTIVITY_LIST",
    exact: true,
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: ActivityListPage,
    navigation: {
      section: "company",
      navGroup: "Template",
      navTitle: "Activity",
      permission: "company-template",
    },
  },
  {
    id: "ACTIVITY_FORM_CREATE",
    path: `${routePrefix}/create`,
    layoutId: "MainLayout",
    component: ActivityFormPage,
  },
  {
    id: "ACTIVITY_FORM_UPDATE",
    path: `${routePrefix}/:id`,
    layoutId: "MainLayout",
    component: ActivityFormPage,
  },
];
