import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  signInButton: {
    backgroundColor: "#FBB040",
    marginTop: "10px",
    color: "#fff",
    fontFamily: "sans-serif",
  },
  iconImage: {
    height: "80px",
    width: "80px",
  },
  downLoadDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    marginTop: "10%",
  },
  tryItNowButton: {
    width: "180px",
    height: "60px",
    backgroundColor: "#205FAD",
    color: "white",
  },
  privacy: {
    cursor: "pointer",
    color: "#321fdb",
  },
});

export default useStyles;
