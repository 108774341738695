import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ id, exact, path, render, isAuthed, redirectTo }) => {
  return isAuthed ? (
    <Route name={id} exact={exact} path={path} render={render} />
  ) : (
    <Redirect
      from={path}
      to={{
        pathname: redirectTo || "/login",
      }}
    />
  );
};

export default PrivateRoute;
