import React from "react";

import "src/assets/scss/style.scss";
import "./App.css";
import { AppContextProvider } from "~contexts";
import { Router } from "./router";

import firebase from "firebase";
import config from "src/config";
const { firebase: firebaseConfig } = config;

// Initialize Firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

/* routes */
const App = () => {
  return (
    <AppContextProvider>
      <Router />
    </AppContextProvider>
  );
};

export default App;
