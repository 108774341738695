//Activity template helper function

/**
 * parse activityTemp.activity/problem and parse to back-end format
 */
export function parseActivityLevel(data) {
  let tmp_data = {};
  data.map((item, key) => {
    delete item.unitType;
    if (item.constraints) {
      if (item.constraints.length === 0) {
        delete item.constraints;
      }
    }
    tmp_data = {
      ...tmp_data,
      [item.fieldUID]: {
        ...item,
        fieldIndex: key,
      },
    };
  });

  return tmp_data;
}

/**
 * parse activity/problem table and parse to back-end format
 */
export function parseActivityTabular(table) {
  let tmp_table = table;
  tmp_table.map((titem) => {
    delete titem.id;

    if (titem.tableColumns.length !== 0) {
      //ADD three default column, these should be the same as the app side
      titem.tableColumns.push({
        columnName: "User",
        columnType: "activityField",
        columnID: "username",
        fieldName: "username",
      });
      titem.tableColumns.push({
        columnName: "Last Updated",
        columnType: "activityField",
        columnID: "lastUpdated",
        fieldName: "lastUpdated",
      });
      titem.tableColumns.push({
        columnName: "Date Created",
        columnType: "activityField",
        columnID: "createdDatetime",
        fieldName: "createdDatetime",
      });
    }
  });
  return tmp_table;
}

/**
 * read activityTemp.activity/problem and parse to front-end format
 */
export function readActivityLevel(data) {
  let tmp_data = [];
  Object.keys(data).forEach(function (key) {
    tmp_data.push(data[key]);
  });
  //Sort according to fieldIndex
  function compare(p) {
    return function (m, n) {
      const a = m[p];
      const b = n[p];
      return a - b;
    };
  }
  tmp_data.sort(compare("fieldIndex"));
  return tmp_data;
}

/**
 * read activity table and parse to front-end format
 * @param {*} table
 * @returns
 */
export function readActivityTabular(table) {
  let tmp_table = table.map((table, key) => {
    let tmpTableColumns = [];
    for (let i = 0; i < table.tableColumns.length; i++) {
      //remove defult column user, last update and creat date
      if (
        table.tableColumns[i].columnName !== "User" &&
        table.tableColumns[i].columnName !== "Last Updated" &&
        table.tableColumns[i].columnName !== "Date Created"
      ) {
        tmpTableColumns.push(table.tableColumns[i]);
      }
    }

    return {
      ...table,
      id: key,
      tableColumns: tmpTableColumns,
    };
  });

  return tmp_table;
}

/**
 * parse activity graph to front-end format
 * @param {*} data
 * @returns
 */
export function readActivityGraph(data) {
  return Object.keys(data).map((key) => {
    return {
      ...data[key],
    };
  });
}

/**
 * parse activity summary to front-end format
 * @param {*} summary
 * @returns
 */
export function readActivitySummary(summary) {
  let tmp = [];
  Object.keys(summary).forEach(function (key) {
    tmp.push(summary[key]);
  });
  return tmp;
}
