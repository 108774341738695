import React, { useState } from "react";

import { Content, Sidebar, Header } from "./components";

const MainLayout = (props) => {
  const { children } = props;

  const [currentTab, setCurrentTab] = useState("company");
  const [showSidebar, setShowSidebar] = useState(true);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="c-app c-default-layout">
      <Sidebar showSidebar={showSidebar} currentTab={currentTab} />
      <div className="c-wrapper">
        <Header toggleSidebar={toggleSidebar} setCurrentTab={setCurrentTab} />
        <div className="c-body">
          <Content>{children}</Content>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
