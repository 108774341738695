import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  main: {
    "& th": {
      fontWeight: "bold",
      backgroundColor: "#d3e2f3",
    },
  },
});

export default useStyles;
