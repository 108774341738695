import React, { createContext, useState } from "react";
import {
  parseSummary,
  readGraphical,
  parseGraphical,
} from "~utils/templates/General";
import { parseNodeData } from "~utils/templates/Category";
import {
  parseJobTabular,
  readJobSummary,
  readJobGraph,
  readJobTabular,
} from "~utils/templates/Job";
import {
  getCategoryTemplate,
  getAllCategory,
  createJobTemplate,
  updateJobTemplate,
  getAllJob,
  deleteJobTemplate,
  getJobTemplate,
} from "~apis/template";
import { useContext } from "react";
import { AuthContext } from "..";

export const CompanyJobTemplateContext = createContext({});

export const CompanyJobTemplateProvider = (props) => {
  const { signedInCompany } = useContext(AuthContext);

  const [templateName, setTemplateName] = useState("");
  const [categoryTemplates, setCategoryTemplates] = useState([]);

  const [jobOutputTabular, setJobOutputTabular] = useState([]);
  const [outputGraphical, setOutputGraphical] = useState([]);
  const [outputSummary, setOutputSummary] = useState([]);

  const [createdDateTime, setCreatedDateTime] = useState("");
  const [lastModified, setLastModified] = useState("");

  const [templateUID, setTemplateUID] = useState("");
  const [templateList, setTemplateList] = useState([]);

  const [isValid, setIsValid] = useState({
    templateName: false,
    tableNames: [],
  });

  //For table
  const [pagedList, setPagedList] = useState([]);
  const [totalCount, setTotalCount] = useState();

  //For review
  const [fromProjectJob, setFromProjectJob] = useState(false);

  const parseTemplateData = (isEdit, fromProject) => {
    let template_obj = {};
    if (!fromProject) {
      categoryTemplates.map((item) => {
        template_obj = {
          ...template_obj,
          [item.uid]: {
            categoryName: item.name,
            categoryTemplateUID: item.uid,
          },
        };
      });
    }
    let tmp_data = {
      jobName: templateName,
      categoryTemplates: fromProject ? categoryTemplates : template_obj,
      output: {
        tabular: parseJobTabular(jobOutputTabular),
        graphical: parseGraphical(outputGraphical),
        summary: parseSummary(outputSummary),
      },
      createdDateTime: isEdit ? createdDateTime : new Date().toISOString(),
      lastModified: new Date().toISOString(),
    };
    return tmp_data;
  };

  const readTemplateData = async (data, fromProject, projectCategoryData) => {
    let tmp = [];
    Object.keys(data.categoryTemplates).forEach(function (key) {
      tmp.push(data.categoryTemplates[key]);
    });

    let tmp_category = [];

    if (fromProject) {
      for (let i = 0; i < projectCategoryData.length; i++) {
        let data = projectCategoryData[i];
        let tmp_c = JSON.parse(JSON.stringify(data.categoryData));
        parseNodeData(tmp_c, 0, null);
        tmp_category[i] = {
          ...tmp_c,
          uid: tmp[i].categoryTemplateUID,
          output: data.output,
        };
      }
    } else {
      for (let i = 0; i < tmp.length; i++) {
        let data = await getCategoryTemplate(
          signedInCompany.id,
          tmp[i].categoryTemplateUID,
        );
        let tmp_c = data.categoryData;
        parseNodeData(tmp_c, 0, null);
        tmp_category[i] = {
          ...tmp_c,
          uid: tmp[i].categoryTemplateUID,
          output: data.output,
        };
      }
    }
    setTemplateUID(data.id);
    setJobOutputTabular(data.output.tabular);
    setOutputGraphical(readGraphical(data.output.graphical));
    setOutputSummary(readJobSummary(data.output.summary));
    setCategoryTemplates(tmp_category);
    setTemplateName(data.jobName);
    setCreatedDateTime(data.createdDateTime);
    setLastModified(data.lastModified);
    setIsValid({
      templateName: true,
      tableNames: [],
    });
  };

  const getCategoryList = (page, limit) => {
    getAllCategory(signedInCompany.id).then((data) => {
      const offset = page * limit;
      const mockPagination = data.slice(offset, offset + limit);
      setPagedList(mockPagination);
      setTotalCount(data.length);
    });
  };

  const saveJobTemplateHandler = async () => {
    const tmp_data = parseTemplateData(false, false);
    await createJobTemplate(signedInCompany.id, tmp_data);
  };

  const editJobTemplateHandler = async () => {
    const tmp_data = parseTemplateData(true, false);
    await updateJobTemplate(signedInCompany.id, templateUID, tmp_data);
  };

  const getAllJobTemplateHandler = (page, limit) => {
    getAllJob(signedInCompany.id).then((data) => {
      const offset = page * limit;
      const mockPagination = data.slice(offset, offset + limit);
      setPagedList(mockPagination);
      setTotalCount(data.length);
    });
  };

  const deleteJobTemplateHandler = (id, page, limit) => {
    deleteJobTemplate(signedInCompany.id, id).then(() => {
      getAllJobTemplateHandler(page, limit);
    });
  };

  const getJobTemplateHandler = async (id) => {
    getJobTemplate(signedInCompany.id, id, false).then((data) => {
      readTemplateData(data);
    });
  };

  /**
   * Get the category data within the given job, parse category into displayable format
   * @param {string} companyID
   * @param {object} jobdata
   * @returns
   */
  const getJobWithCategory = async (jobId) => {
    let data = await getJobTemplate(signedInCompany.id, jobId, true);
    let tmp_category = [];

    console.log(Object.assign({}, data));
    if (data.categories) {
      data.categories.map((item) => {
        let tmp_c = item.categoryData;
        let uid = "";
        Object.keys(data.categoryTemplates).map((keyitem) => {
          if (
            data.categoryTemplates[keyitem].categoryName === item.templateName
          ) {
            uid = keyitem;
          }
        });

        parseNodeData(tmp_c, 0, null);

        tmp_category.push({
          ...tmp_c,
          uid: uid,
          output: item.output,
        });
      });
    }

    let jobData = {
      jobName: data.jobName,
      categoryTemplates: tmp_category,
      output: {
        tabular: readJobTabular(data.output.tabular),
        graphical: readJobGraph(data.output.graphical),
        summary: readJobSummary(data.output.summary),
      },
    };

    return jobData;
  };

  return (
    <CompanyJobTemplateContext.Provider
      value={{
        templateName,
        setTemplateName,
        categoryTemplates,
        setCategoryTemplates,
        jobOutputTabular,
        setJobOutputTabular,
        outputGraphical,
        setOutputGraphical,
        outputSummary,
        setOutputSummary,
        createdDateTime,
        setCreatedDateTime,
        lastModified,
        setLastModified,
        templateUID,
        setTemplateUID,
        templateList,
        setTemplateList,
        isValid,
        setIsValid,

        parseTemplateData,
        readTemplateData,
        getCategoryList,
        saveJobTemplateHandler,
        editJobTemplateHandler,
        getAllJobTemplateHandler,
        deleteJobTemplateHandler,
        getJobTemplateHandler,

        getJobWithCategory,

        //For table
        pagedList,
        setPagedList,
        totalCount,
        setTotalCount,

        //For review
        fromProjectJob,
        setFromProjectJob,
      }}
    >
      {props.children}
    </CompanyJobTemplateContext.Provider>
  );
};
