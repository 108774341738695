import React, { useEffect, useState } from "react";
import { CFormGroup, CLabel, CInput, CInvalidFeedback } from "@coreui/react";

import { PasswordInputPropTypes } from "./PasswordInput.types";

// default non-empty validator
// TODO: add confirm password input
const defaultValidator = (value) => {
  return !!value && value.length > 0;
};

const PasswordInput = (props) => {
  const {
    label,
    isRequired,
    id,
    name,
    placeholder,
    initialValue,
    onChange,
    autoComplete,
    validator,
    invalidMessage,
    disabled,
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setInputValue(initialValue || "");
  }, [initialValue]);

  const onChangeHandler = (e) => {
    const value = e.target.value;
    let isDataValid = true;
    if (isRequired) {
      if (validator) {
        isDataValid = validator(value);
      } else {
        isDataValid = defaultValidator(value);
      }
    }
    setIsValid(isDataValid);
    setInputValue(value);
    onChange(name, value, isDataValid);
  };

  return (
    <CFormGroup>
      {label ? (
        <CLabel htmlFor={id}>
          {label} {isRequired ? "*" : ""}
        </CLabel>
      ) : null}
      <CInput
        type="password"
        id={id}
        name={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={onChangeHandler}
        autoComplete={autoComplete}
        invalid={!isValid}
        disabled={disabled}
      />
      {invalidMessage ? (
        <CInvalidFeedback>{invalidMessage}</CInvalidFeedback>
      ) : null}
    </CFormGroup>
  );
};

PasswordInput.propTypes = PasswordInputPropTypes;

export default PasswordInput;
