import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import { Button } from "@material-ui/core";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import AOS from "aos";
import "aos/dist/aos.css";

import homepic from "~assets/img/phone1.png";
import contactimg from "~assets/img/about_1.jpg";
import downloadApple from "~assets/img/appleStore-small.png";
import downloadGoogle from "~assets/img/playStore-small.png";
import cloud from "~assets/img/cloud-computing.png";
import connection from "~assets/img/analytics.png";
import diagram from "~assets/img/statistics.png";
import "~assets/css/style.css";
import useStyles from "./LandingPage.jss";

const LandingPage = () => {
  AOS.init({});
  const classes = useStyles();

  const [isTop, setIsTop] = useState(true);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [subject, setSubject] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const history = useHistory();

  const handleClick = () => {
    history.push({ pathname: "/login" });
  };

  function getScrollTop() {
    let scrollTop = 0;
    if (document.documentElement && document.documentElement.scrollTop) {
      scrollTop = document.documentElement.scrollTop;
    } else if (document.body) {
      scrollTop = document.body.scrollTop;
    }
    return scrollTop;
  }
  document.onscroll = function () {
    const scrollValue = getScrollTop();
    if (scrollValue === 0) {
      setIsTop(true);
    } else {
      setIsTop(false);
    }
  };

  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return (
      <div className="mailTo">
        <a href={`mailto:${email}${params}`}>{children}</a>
      </div>
    );
  };

  const handleInput = (event) => {
    if (event.target.name === "firstName") {
      setFirstName(event.target.value);
    } else if (event.target.name === "lastName") {
      setLastName(event.target.value);
    } else if (event.target.name === "subject") {
      setSubject(event.target.value);
    } else if (event.target.name === "email") {
      setEmail(event.target.value);
    } else if (event.target.name === "message") {
      setMessage(event.target.value);
    }
  };

  return (
    <>
      <div>
        <div className="site-wrap">
          <div className={isTop ? "headerLeftTop" : "headerLeftNotTop"}>
            JOB SITE UPDATE
          </div>
          <Scrollspy
            className={isTop ? "scrollspyTop" : "scrollspyNotTop"}
            items={[
              "home-section",
              "about-section",
              "Download-section",
              "View-section",
              "Contact-section",
            ]}
            currentClassName="isCurrent"
          >
            <li>
              <a href="#home-section">Home</a>
            </li>
            <li>
              <a href="#about-section">About</a>
            </li>
            <li>
              <a href="#Download-section">Download</a>
            </li>
            <li>
              <a href="#View-section">View Data</a>
            </li>
            <li>
              <a href="#Contact-section">Contact us</a>
            </li>
            <div className="headerButton">
              <Button
                className={classes.signInButton}
                onClick={() => handleClick()}
              >
                Sign In
              </Button>
            </div>
          </Scrollspy>
          <div className="site-mobile-menu site-navbar-target">
            <div className="site-mobile-menu-header">
              <div className="site-mobile-menu-close mt-3">
                <span className="icon-close2 js-menu-toggle"></span>
              </div>
            </div>
            <div className="site-mobile-menu-body"></div>
          </div>

          <div className="intro-section" id="home-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 mr-auto" data-aos="fade-up">
                  <div>
                    <div>
                      <h1>
                        Upload <br />
                        Job site data
                      </h1>
                      <p className="mb-5">
                        Upload your job site data to the cloud anywhere,
                        anytime. Improve work efficiency and facilitate project
                        management
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 ml-auto"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div>
                    <img src={homepic} alt="home page" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="site-section bg-light"
            id="about-section"
            data-aos="fade"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mb-5">
                  <h2 className="section-title">Our Approach</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div className="process bg-white rounded">
                    <div>
                      <span className="flaticon-glasses display-4 text-primary mb-4 d-inline-block"></span>
                      <img
                        src={cloud}
                        className={classes.iconImage}
                        alt="cloud"
                      />
                      <h3>Cloud Storage</h3>
                      <p>
                        JSU stores the data uploaded by users on cloud server.
                        Data is shared on all devices and can be used on any
                        device
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div className="process bg-white rounded">
                    <div>
                      <span className="flaticon-vector display-4 text-primary mb-4 d-inline-block"></span>
                      <img
                        alt="connection"
                        src={connection}
                        className={classes.iconImage}
                      />
                      <h3>Project monitoring</h3>
                      <p>
                        JSU provides data driven project monitoring. Make use of
                        project data to take the guessedness out of decisions
                        and organize work, improve efficiency, and manage risks
                        more effectively.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div className="process bg-white rounded">
                    <div>
                      <span className="flaticon-monitor display-4 text-primary mb-4 d-inline-block"></span>
                      <img
                        alt="diagram"
                        src={diagram}
                        className={classes.iconImage}
                      />
                      <h3>REAL-TIME ANALYSIS</h3>
                      <p>
                        Get live interactive analytics reports to get in control
                        of project progress
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-section" id="Download-section">
            <div className="container">
              <div
                className="row text-center justify-content-center mb-5"
                data-aos="fade-up"
              >
                <div className="col-lg-6 mb-5 text-center">
                  <h2 className="section-title w-100 mx-auto">Download Now</h2>
                  <p>
                    Our application is now available on both Android and iOS
                    platforms
                  </p>
                  <div className={classes.downLoadDiv}>
                    <div>
                      <a
                        href="https://apps.apple.com/ca/app/job-site-update/id1570504842"
                        target="_blank"
                        rel="noreferrer"
                        title="Download on Apple Store"
                      >
                        <img
                          src={downloadApple}
                          alt="Download on the Apple store icon"
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.arba.jobsiteupdate"
                        target="_blank"
                        rel="noreferrer"
                        title="Download on Android"
                      >
                        <img
                          src={downloadGoogle}
                          alt="Download on the Google Play store icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-section bg-light" id="View-section">
            <div className="container">
              <div
                className="row text-center justify-content-center mb-5"
                data-aos="fade-up"
              >
                <div className="col-lg-6 mb-5 text-center">
                  <h2 className="section-title w-100 mx-auto">View Data</h2>
                  <p>
                    View your project data and analysis reports and keep track
                    of your project progress in real time.
                  </p>
                  <Button
                    className={classes.tryItNowButton}
                    onClick={() => handleClick()}
                  >
                    Try It Now
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="site-section" id="Contact-section" data-aos="fade">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 order-1 order-md-2 mb-5 mb-md-0">
                  <img
                    src={contactimg}
                    alt="contact button"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-6 mr-auto order-2 order-md-1">
                  <h2 className="section-title mb-3">Contact Us</h2>
                  <p className="mb-5">
                    Got a question about our product? Leave a message! We'll get
                    in touch with you.
                  </p>

                  <form method="post">
                    <div className="form-group row">
                      <div className="col-md-6 mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First name"
                          name="firstName"
                          value={firstName}
                          onChange={(event) => handleInput(event)}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last name"
                          name="lastName"
                          value={lastName}
                          onChange={(event) => handleInput(event)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Subject"
                          name="subject"
                          value={subject}
                          onChange={(event) => handleInput(event)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={(event) => handleInput(event)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <textarea
                          className="form-control"
                          id=""
                          cols="30"
                          rows="10"
                          placeholder="Write your message here."
                          name="message"
                          value={message}
                          onChange={(event) => handleInput(event)}
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <Mailto
                          email="info@jobsiteupdate.com"
                          subject={subject}
                          body={
                            message + "From:" + firstName + lastName + email
                          }
                        >
                          Send Message
                        </Mailto>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer-section bg-light">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <h3>About Us</h3>
                  <p>
                    Consortium for Engineered Trenchless Technology (CETT)
                    <br />
                    7-389 DICE, 9211 116 Street NW
                    <br />
                    Edmonton, AB, Canada T6G 1H9
                  </p>
                  <p>
                    Email:{" "}
                    <a href="mailto:info@jobsiteupdate.com">
                      info@jobsiteupdate.com
                    </a>
                    <br />
                    Phone: (780) 492 5106
                  </p>
                </div>

                <div className="col-md-3 ml-auto">
                  <h3>Links</h3>
                  <ul className="list-unstyled footer-links">
                    <li>
                      <a href="#home-section" className="smoothscroll">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#about-section" className="smoothscroll">
                        About
                      </a>
                    </li>
                    <li>
                      <a href="#Download-section" className="smoothscroll">
                        Download
                      </a>
                    </li>
                    <li>
                      <a href="#View-section" className="smoothscroll">
                        View Data
                      </a>
                    </li>
                    <li>
                      <a href="#Contact-section" className="smoothscroll">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-4">
                  <h3>Policy</h3>
                  <ul className="list-unstyled footer-links">
                    <li>
                      <a
                        href="/privacy"
                        target="_blank"
                        className="smoothscroll"
                      >
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row pt-5 mt-5 text-center">
                <div className="col-md-12">
                  <div className="border-top pt-5">
                    &copy; {new Date().getFullYear()} Job Site Update
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
