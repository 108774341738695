import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  paper: {
    flex: "auto",
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    boxShadow: "none !important",
    borderRadius: "0 !important",
  },
  tableContainer: {
    flex: "auto",
    borderBottom: "2px solid #ebedef",
  },
});

export default useStyles;
