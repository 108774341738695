import React from "react";
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Divider,
} from "@material-ui/core";

import { Avatar } from "~components";
import useStyles from "./TransferList.jss";

export const TransferList = (props) => {
  const {
    choiceListTitle,
    choiceList,
    onChoiceItemsSelect,
    chosenListTitle,
    chosenList,
    onChosenItemsSelect,
    showAvatar,
    disabled = false,
  } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={5}>
        <Card>
          <CardHeader
            className={classes.cardHeader}
            subheader={choiceListTitle}
          />
          <Divider />
          <List className={classes.itemList} dense component="div" role="list">
            {choiceList.map((item, idx) => {
              return (
                <ListItem
                  key={item.id || idx}
                  role="listitem"
                  button
                  className={classes.itemContainer}
                  disabled={disabled}
                  onClick={() => onChoiceItemsSelect([item])}
                >
                  {showAvatar && (
                    <ListItemIcon className={classes.itemAvatarContainer}>
                      <Avatar name={item.name} src={item.avatarUrl} />
                    </ListItemIcon>
                  )}
                  <div style={{ marginLeft: 10 }}>
                    <ListItemText primary={item.name} />
                    <ListItemText
                      className={classes.itemSubTitle}
                      secondary={item.subTitle}
                    />
                    <ListItemText
                      className={classes.itemSubTitle}
                      secondary={item.subTitle2}
                    />
                  </div>
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Card>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => onChoiceItemsSelect(choiceList)}
            disabled={disabled || choiceList.length === 0}
            aria-label="move selected right"
          >
            &gt;&gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => onChosenItemsSelect(chosenList)}
            disabled={disabled || chosenList.length === 0}
            aria-label="move selected left"
          >
            &lt;&lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Card>
          <CardHeader
            className={classes.cardHeader}
            subheader={chosenListTitle}
          />
          <Divider />
          <List className={classes.itemList} dense component="div" role="list">
            {chosenList.map((item, idx) => {
              return (
                <ListItem
                  key={item.id || idx}
                  role="listitem"
                  button
                  className={classes.itemContainer}
                  onClick={() => onChosenItemsSelect([item])}
                  disabled={disabled}
                >
                  {showAvatar && (
                    <ListItemIcon className={classes.itemAvatarContainer}>
                      <Avatar name={item.name} src={item.avatarUrl} />
                    </ListItemIcon>
                  )}
                  <div style={{ marginLeft: 10 }}>
                    <ListItemText primary={item.name} />
                    <ListItemText
                      className={classes.itemSubTitle}
                      secondary={item.subTitle}
                    />
                    <ListItemText
                      className={classes.itemSubTitle}
                      secondary={item.subTitle2}
                    />
                  </div>
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Card>
      </Grid>
    </Grid>
  );
};
