import * as React from "react";

const Page404 = React.lazy(() => import("./Page404"));

const Page500 = React.lazy(() => import("./Page500"));

const routePrefix = "/error";

export const ErrorPageRoutes = [
  {
    id: "ERROR_404",
    path: `${routePrefix}/404`,
    component: Page404,
  },
  {
    id: "ERROR_500",
    path: `${routePrefix}/500`,
    component: Page500,
  },
];
