import React from "react";
import { CircularProgress } from "@material-ui/core";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";

import useStyles from "./FileLoading.jss";

const FileLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageOutlinedIcon fontSize="large" />
      <CircularProgress size={20} />
    </div>
  );
};

export default FileLoading;
