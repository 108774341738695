import PropTypes from "prop-types";

export const FormPropTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      rowNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      colSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      type: PropTypes.string,
    }),
  ).isRequired,
  onSubmit: PropTypes.func,
  submitBtnText: PropTypes.string,
  onCancel: PropTypes.func,
  cancelBtnText: PropTypes.string,
};
