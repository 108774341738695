import React from "react";
import { styled } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@material-ui/icons/ArrowDownwardOutlined";
import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import EditIcon from "@material-ui/icons/Edit";

/**
 * Some customized MUI component (just buttons for now)
 */
export const IconButtonTense = styled(IconButton)({
  padding: "5px",
});

export const CreateOutlinedIconColored = styled(CreateOutlinedIcon)({
  color: "#205FAD",
});

export const DeleteOutlinedIconColored = styled(DeleteOutlinedIcon)({
  color: "#B22222",
});

export const TableRowEditButton = (props) => {
  return (
    <IconButtonTense {...props}>
      <CreateOutlinedIconColored fontSize="small" />
    </IconButtonTense>
  );
};

export const TableRowInfoButton = (props) => {
  return (
    <IconButtonTense {...props}>
      <InfoOutlinedIcon fontSize="small" />
    </IconButtonTense>
  );
};

export const TableRowDeleteButton = (props) => {
  return (
    <IconButtonTense {...props}>
      <DeleteOutlinedIconColored fontSize="small" />
    </IconButtonTense>
  );
};

export const TableMoveUpButton = (props) => {
  return (
    <IconButtonTense {...props}>
      <ArrowUpwardOutlinedIcon fontSize="small" />
    </IconButtonTense>
  );
};

export const TableMoveDownButton = (props) => {
  return (
    <IconButtonTense {...props}>
      <ArrowDownwardOutlinedIcon fontSize="small" />
    </IconButtonTense>
  );
};

export const TableUserButton = (props) => {
  return (
    <IconButtonTense {...props}>
      <PersonIcon fontSize="small" />
    </IconButtonTense>
  );
};

export const TableBusinessButton = (props) => {
  return (
    <IconButtonTense {...props}>
      <BusinessIcon fontSize="small" />
    </IconButtonTense>
  );
};

export const TableEditButton = (props) => {
  return (
    <IconButtonTense {...props}>
      <EditIcon fontSize="small" />
    </IconButtonTense>
  );
};
