import * as React from "react";

import { CompanyInfoRoutes } from "./CompanyInfo";
import { CompanyActivityRoutes } from "./CompanyActivityTemplate";
import { CompanyCategoryRoutes } from "./CompanyCategoryTemplate";
import { CompanyJobRoutes } from "./CompanyJobTemplate";
import { CompanyProjectTemplateRoutes } from "./CompanyProjectTemplate";
import { CompanyProjectRoutes } from "./CompanyProject";
import { CompanyUserRoutes } from "./CompanyUser";
import { CompanyUserRequestRoutes } from "./CompanyUserRequest";

const CompanyIndex = React.lazy(() => import("./Company"));
const routePrefix = "/company";

// all routes for sub pages
export const companyPageRoutes = [
  ...CompanyInfoRoutes,
  // Project section
  ...CompanyProjectRoutes,
  // Template section
  ...CompanyActivityRoutes,
  ...CompanyCategoryRoutes,
  ...CompanyJobRoutes,
  ...CompanyProjectTemplateRoutes,
  // User section
  ...CompanyUserRoutes,
  ...CompanyUserRequestRoutes,
];

// default redirect to this path
export const homePagePath = `${routePrefix}/template/activity`;

// used for sidebar rendering by defined navigation
export const companySidebarNavs = companyPageRoutes.filter(
  (route) => !!route.navigation,
);

// used for Router rendering
export const CompanyRoutes = [
  {
    id: "COMPANY_INDEX",
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: CompanyIndex,
  },
];
