import * as React from "react";

const ProfilePage = React.lazy(() => import("./Profile"));

const routePrefix = "/profile";

export const ProfileRoutes = [
  {
    id: "PROFILE_PAGE",
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: ProfilePage,
  },
];
