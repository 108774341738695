import PropTypes from "prop-types";
import React from "react";

export const CardPropTypes = {
  title: PropTypes.node, //text+button
  content: PropTypes.node,
  button: PropTypes.node,
  collapse: PropTypes.bool,
};

export const CardDefaultProps = {
  title: "",
  content: <></>,
  button: <></>,
  collapse: false,
};
