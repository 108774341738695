// copied from: https://mui.com/components/avatars/

const stringToColor = (string) => {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
};

export const stringAvatar = (name) => {
  if (name) {
    const arr = name.split(" ").filter((i) => !!i);
    return {
      bgColor: stringToColor(name),
      children: `${arr[0][0]}${arr.length > 1 ? arr[1][0] : ""}`,
    };
  }
};
