import PropTypes from "prop-types";

export const TextFieldPropTypes = {
  fieldName: PropTypes.string,
  fieldUID: PropTypes.string,
  fieldType: PropTypes.string,
  required: PropTypes.bool,
  constraints: PropTypes.arrayOf(PropTypes.object),
  validator: PropTypes.array,
  setData: PropTypes.array,
};
