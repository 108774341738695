import * as React from "react";

const CompanyUserRequestIndexPage = React.lazy(() =>
  import("./CompanyUserRequest"),
);

const routePrefix = "/company/user-request";

export const CompanyUserRequestRoutes = [
  {
    id: "COMPANY_USER_REQUEST",
    exact: true,
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: CompanyUserRequestIndexPage,
    navigation: {
      section: "company",
      navGroup: "Administration",
      navTitle: "Join Request",
      permission: "company-user-request",
    },
  },
];
