import * as React from "react";

const LandingPage = React.lazy(() => import("./LandingPage"));

const routePrefix = "/";

export const LandingPageRoutes = [
  {
    id: "LANDING_PAGE",
    exact: true,
    path: `${routePrefix}`,
    layoutId: "LandingPageLayout",
    component: LandingPage,
  },
];
