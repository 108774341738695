import PropTypes from "prop-types";
import React from "react";

export const JSUDialogPropTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  width: PropTypes.oneOf(["sm", "xs", "md", "lg", "xl"]),
  title: PropTypes.string,
  content: PropTypes.node,
  buttons: PropTypes.node,
};

export const JSUDialogDefaultProps = {
  open: false,
  onClose: null,
  onSave: null,
  width: "sm",
  title: "",
  content: <></>,
  buttons: <></>,
};
