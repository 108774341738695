import React, { createContext, useState, useEffect } from "react";
import firebase from "firebase";

import * as auth from "~apis/auth";
import { getUserById } from "~apis/user";

export const AuthContext = createContext({});

export const AuthContextProvider = (props) => {
  const { children } = props;

  const [user, setUser] = useState(null);
  const [signedInCompany, setSignedInCompany] = useState(null);
  const [authedCompanyList, setAuthedCompanyList] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);

  useEffect(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const storedUserInfo = localStorage.getItem("userInfo");
      const storedSignedInCompany = localStorage.getItem("signedInCompany");
      if (storedSignedInCompany) {
        setSignedInCompany(JSON.parse(storedSignedInCompany));
      }
      if (storedUserInfo) {
        const userInfo = JSON.parse(storedUserInfo);
        setUser(userInfo);
        await _getAuthedCompanyList(userInfo.id);
      }
    }
    firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        const userToken = await user.getIdToken();
        localStorage.setItem("token", userToken);
        await refreshUserInfo(user.uid);
      }
    });
  }, []);

  // get the authed companies that the user belongs to
  const _getAuthedCompanyList = async (userId) => {
    const companyList = await auth.getAuthedCompanyList();
    if (companyList) {
      setAuthedCompanyList(companyList);
      // by default signin the first one
      const signedInCompany = localStorage.getItem("signedInCompany");
      if (companyList.length > 0 && !signedInCompany) {
        await signInCompany(companyList[0], userId);
      }
    }
  };

  // after the user switches to one company, this function will be called
  const signInCompany = async (company, userId = null) => {
    if (user || userId) {
      userId = userId || user.id;
      const { token, companyAuth } = await auth.signInCompany(
        company.id,
        userId,
      );
      const userCredential = await firebase.auth().signInWithCustomToken(token);
      const newToken = await userCredential.user?.getIdToken();
      if (newToken) {
        const companyWithRole = { ...company, companyAuth };
        setSignedInCompany(companyWithRole);
        localStorage.setItem(
          "signedInCompany",
          JSON.stringify(companyWithRole),
        );
        localStorage.setItem("token", newToken);
      }
    }
  };

  // TODO: the userID should be handled by backend using JWT
  // and when the useInfo can be fetched, meaning the token is valid
  const refreshUserInfo = async (userId) => {
    const data = await getUserById(userId);
    if (data) {
      localStorage.setItem("userInfo", JSON.stringify(data));
      setUser(data);
    }
  };

  // after login, set token then fetch user and user-related data
  const login = async (username, password) => {
    let firebaseUser;
    try {
      firebaseUser = await auth.login(username, password);
    } catch (error) {
      alert("login failed, error: " + error.message);
      return;
    }
    const userId = firebaseUser.uid;
    const token = await firebaseUser.getIdToken();
    if (token) {
      localStorage.clear();
      localStorage.setItem("token", token);
      await refreshUserInfo(userId);
      await _getAuthedCompanyList(userId);
    }
    return true;
  };

  const logout = async () => {
    await auth.logout();
    setUser(null);
    setSignedInCompany(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        refreshUserInfo,
        login,
        logout,
        authedCompanyList,
        signedInCompany,
        signInCompany,
        currentProject,
        setCurrentProject,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
