//General helper functions for templates

import { constraintAvailable } from "../const";
/**
 *  Generate ID automatically, remove all symbol and change string to camel string
 * @param {string} str
 * @returns
 */
export function nameToID(str) {
  str = str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
  str = str.slice(0, 1).toLowerCase() + str.slice(1);
  str = str.split(" ").join("");
  str = str.replace(/[^0-9a-z]/gi, "");
  return str;
}

// Field
// Field function are used for creating activity(problem) template and project template

/**
 * Check if all the input of field is valid
 * @param {*} isValid
 * @param {*} inputValue
 * @param {*} dropdownOptions
 * @param {*} haveConstraint
 * @param {*} constraints
 * @param {*} unitType
 * @param {*} dropdownUnitOptions
 * @returns
 */
export function checkFieldValidation(
  isValid,
  inputValue,
  dropdownOptions,
  haveConstraint,
  constraints,
  unitType,
  dropdownUnitOptions,
) {
  let validValue = isValid.fieldName && isValid.fieldUID && isValid.fieldType;
  if (inputValue.fieldType === "dropdown") {
    dropdownOptions.forEach((item) => {
      validValue = validValue && item.isValid;
    });
  } else if (
    constraintAvailable.indexOf(inputValue.fieldType) !== -1 &&
    haveConstraint
  ) {
    constraints.forEach((item) => {
      validValue = validValue && item.typeIsValid && item.valueIsValid;
    });
  }
  if (inputValue.fieldType === "number") {
    validValue = validValue && isValid.unitType;
    if (unitType === "fixed") {
      validValue = validValue && isValid.unitFixed;
    } else if (unitType === "dropdown") {
      dropdownUnitOptions.forEach((item) => {
        validValue = validValue && item.nameIsValid && item.weightIsValid;
      });
    }
  }
  return validValue;
}

/**
 * Parse field data from front-end format to back-end format
 * @param {*} data
 * @param {*} dropdownOptions
 * @param {*} checkboxOptions
 * @param {*} dropdownUnitOptions
 * @param {*} defaultUnit
 * @param {*} constraints
 * @param {*} acceptDecimal
 * @param {*} datetimeType
 * @param {*} fileType
 * @param {*} unitType
 * @param {*} unitFixed
 * @param {*} haveConstraint
 * @returns
 */
export function parseFieldData(
  data,
  dropdownOptions,
  checkboxOptions,
  dropdownUnitOptions,
  defaultUnit,
  constraints,
  acceptDecimal,
  datetimeType,
  fileType,
  unitType,
  unitFixed,
  haveConstraint,
) {
  let tData = data;
  if (data.fieldType === "dropdown") {
    let unit_tmp = {
      unitType: unitType,
      unitValue: null,
    };
    if (unitType === "fixed") {
      unit_tmp.unitValue = unitFixed;
    } else if (unitType === "dropdown") {
      dropdownUnitOptions.map((item) => {
        let unitUID = nameToID(item.name);
        unit_tmp.unitValue = {};
        unit_tmp.unitValue[unitUID] = {
          unitUID: unitUID,
          unitName: item.name,
          unitWeight: item.weight,
        };
      });
      unit_tmp.defaultUnit = defaultUnit;
    } else {
      delete unit_tmp.unitValue;
    }
    tData.unit = unit_tmp;
    tData.dropdownValues = dropdownOptions.map((item) => {
      return {
        dropdownName: item.value,
        dropdownValue: item.value,
        dropdownType: item.type,
      };
    });
  } else if (data.fieldType === "checkbox") {
    tData.checkboxValues = checkboxOptions.map((item) => {
      return {
        checkboxName: item.value,
        checkboxValue: item.value,
      };
    });
  } else if (data.fieldType === "number") {
    let unit_tmp = {
      unitType: unitType,
      unitValue: null,
    };

    if (unitType === "fixed") {
      unit_tmp.unitValue = unitFixed;
    } else if (unitType === "dropdown") {
      unit_tmp.unitValue = {};
      dropdownUnitOptions.map((item) => {
        let unitUID = nameToID(item.name);
        unit_tmp.unitValue[unitUID] = {
          unitUID: nameToID(item.name),
          unitName: item.name,
          unitWeight: item.weight,
        };
      });
      unit_tmp.defaultUnit = defaultUnit;
    } else {
      delete unit_tmp.unitValue;
    }
    tData.unit = unit_tmp;
    tData.acceptDecimal = acceptDecimal;
  } else if (data.fieldType === "datetime") {
    tData.datetimeType = datetimeType;
  } else if (data.fieldType === "file") {
    tData.fileType = fileType;
  }

  if (constraintAvailable.indexOf(data.fieldType) !== -1) {
    if (haveConstraint) {
      tData.constraints = constraints.map((item) => {
        return {
          constraintType: item.type,
          constraintValue: item.value,
        };
      });
    }
  }

  return tData;
}

/**
 * Reset validation
 * @param {*} data
 * @returns
 */
export function resetValidationForEdit(data) {
  let valid = {
    fieldName: true,
    fieldUID: true,
    fieldType: true,
  };
  if (data.unit) {
    if (data.unit.unitType) {
      valid.unitType = true;
      if (data.unit.unitType === "fixed") {
        valid.unitFixed = true;
      }
    }
  }
  return valid;
}

/**
 * Read Dropdown unit options when editing field data
 * @param {object} data
 * @returns
 */
export function readDropdownUnitOptions(data) {
  let tmp = [];
  Object.keys(data).map((keyItem) => {
    +tmp.push({
      id: keyItem,
      name: data[keyItem].unitName,
      weight: data[keyItem].unitWeight,
      nameIsValid: true,
      weightIsValid: true,
    });
  });

  return tmp;
}

/**
 * Logic to decide whether to show the add constraint button or not
 * @param {boolean} isEdit
 * @param {Object} editDataInit
 * @returns
 */
export function readShowAddConstraint(isEdit, editDataInit) {
  if (isEdit) {
    if (constraintAvailable.indexOf[editDataInit.fieldType] !== -1) {
      if (editDataInit.constraints) {
        return false;
      } else {
        return true;
      }
    }
  } else {
    return false;
  }
}

/**
 * parse array to object, summaryID as key
 * @param {*} summaryArray
 */
export function parseSummary(summaryArray) {
  let tmp = {};
  summaryArray.map((item) => {
    delete item.id;
    tmp = {
      ...tmp,
      [item.summaryID]: {
        ...item,
      },
    };
  });

  return tmp;
}

/**
 * get options for summary field
 * @param {*} table
 * @returns
 */
export function getSummaryOptions(table) {
  let options = [];
  table.map((item) => {
    item.tableColumns.map((titem) => {
      options.push({
        name: titem.columnName,
        id: titem.columnID,
      });
    });
  });
  return options;
}

/**
 * read graph object to array
 * @param {object} graph
 * @returns
 */
export function readGraphical(graph) {
  let graphList = [];
  Object.keys(graph).forEach(function (key) {
    graphList.push(graph[key]);
  });
  return graphList;
}

/**
 * parse graph array to object
 * @param {array} graph
 * @returns
 */
export function parseGraphical(graph) {
  let graphObject = {};
  graph.map((item) => {
    graphObject[item.graphID] = item;
  });
  return graphObject;
}
