import * as React from "react";

import { CompanyProjectRoutes } from "../Company/CompanyProject";

const ProjectUser = React.lazy(() => import("./ProjectUser"));
const ProjectResult = React.lazy(() => import("./ProjectResult"));
const ProjectIndex = React.lazy(() => import("./Project"));
const ProjectCompany = React.lazy(() => import("./ProjectCompany"));
const ProjectData = React.lazy(() => import("./ProjectData"));

const routePrefix = "/project";

// all routes for sub pages
export const projectPageRoutes = [
  ...CompanyProjectRoutes,
  {
    id: "PROJECT_RESULT",
    exact: true,
    path: `${routePrefix}/result`,
    layoutId: "MainLayout",
    component: ProjectResult,
    navigation: {
      section: "project",
      navGroup: "Project",
      navTitle: "Result",
      permission: "project-result",
    },
  },
  {
    id: "PROJECT_DATA",
    exact: true,
    path: `${routePrefix}/data`,
    layoutId: "MainLayout",
    component: ProjectData,
    navigation: {
      section: "project",
      navGroup: "Project",
      navTitle: "Raw Data",
      permission: "project-data",
    },
  },
  {
    id: "PROJECT_USER",
    exact: true,
    path: `${routePrefix}/user`,
    layoutId: "MainLayout",
    component: ProjectUser,
    navigation: {
      section: "project",
      navGroup: "Management",
      navTitle: "Project Staff",
      permission: "project-user",
    },
  },
  {
    id: "PROJECT_COMPANY",
    exact: true,
    path: `${routePrefix}/company`,
    layoutId: "MainLayout",
    component: ProjectCompany,
    navigation: {
      section: "project",
      navGroup: "Management",
      navTitle: "Contractor",
      permission: "project-company",
    },
  },
];

// default redirect to this path
export const homePagePath = CompanyProjectRoutes[0].path;

// used for sidebar rendering by defined navigation
export const projectSidebarNavs = projectPageRoutes.filter(
  (route) => !!route.navigation,
);

// used for Router rendering
export const ProjectRoutes = [
  {
    id: "PROJECT_INDEX",
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: ProjectIndex,
  },
];
