import { get, post, put, del } from "~utils/httpClient";
import config from "~config";

const { serverHost } = config;

//Activity

/**
 * Save new activity template
 * @param {string} companyID
 * @param {object} activityTemplateData activity template object
 */
export const createActivityTemplate = async (
  companyID,
  activityTemplateData,
) => {
  const { ok, data } = await post(
    `${serverHost}/api/v0/Company/${companyID}/ActivityTemplate`,
    {
      data: {
        activityTemplateData,
      },
    },
  );
  return ok ? data : null;
};

/**
 * Save activity template based on existed one
 * @param {string} companyID
 * @param {string} templateUID
 * @param {object} activityTemplateData
 */
export const updateActivityTemplate = async (
  companyID,
  templateUID,
  activityTemplateData,
) => {
  const { ok, data } = await put(
    `${serverHost}/api/v0/Company/${companyID}/ActivityTemplate/${templateUID}`,
    {
      data: {
        activityTemplateData,
      },
    },
  );
  return ok ? data : null;
};

/**
 * Delete activity template
 * @param {string} companyID
 * @param {string} templateUID
 */
export const deleteActivityTemplate = async (companyID, templateUID) => {
  const { ok, data } = await del(
    `${serverHost}/api/v0/Company/${companyID}/ActivityTemplate/${templateUID}`,
  );
  return ok ? data : null;
};

/**
 * Get activity template with companyID and templateUID
 * @param {string} companyID
 * @param {string} templateUID
 */
export const getActivityTemplate = async (companyID, templateUID) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Company/${companyID}/ActivityTemplate/${templateUID}`,
  );
  return ok ? data : null;
};

/**
 * Get all activity templates inside current company
 * @param {string} companyID
 * @returns {object[]} list of activity template array
 */
export const getAllActivity = async (companyID) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Company/${companyID}/ActivityTemplate`,
  );
  return ok ? data : [];
};

//Category

/**
 * Get all category inside current company
 * @param {string} companyID
 * @returns {object[]} list of category template
 */
export const getAllCategory = async (companyID) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Company/${companyID}/CategoryTemplate`,
  );
  return ok ? data : [];
};

/**
 * Get category template
 * @param {string} companyID
 * @param {string} categoryID
 * @returns {object} category object
 */
export const getCategoryTemplate = async (companyID, categoryID) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Company/${companyID}/CategoryTemplate/${categoryID}`,
  );
  return ok ? data : null;
};

/**
 * Create new category template
 * @param {string} companyID
 * @param {object} categoryTemplateData parsed category template data
 */
export const createCategoryTemplate = async (
  companyID,
  categoryTemplateData,
) => {
  const { ok, data } = await post(
    `${serverHost}/api/v0/Company/${companyID}/CategoryTemplate`,
    {
      data: {
        categoryTemplateData,
      },
    },
  );
  return ok ? data : null;
};

/**
 * Save category template based on existed one
 * @param {string} companyID
 * @param {object} templateData  parsed category template data
 */
export const updateCategoryTemplate = async (
  companyID,
  templateUID,
  categoryTemplateData,
) => {
  const { ok, data } = await put(
    `${serverHost}/api/v0/Company/${companyID}/CategoryTemplate/${templateUID}`,
    {
      data: {
        categoryTemplateData,
      },
    },
  );
  return ok ? data : null;
};

/**
 * Delete category template
 * @param {string} companyID
 * @param {string} templateUID
 */
export const deleteCategoryTemplate = async (companyID, templateUID) => {
  const { ok, data } = await del(
    `${serverHost}/api/v0/Company/${companyID}/CategoryTemplate/${templateUID}`,
  );
  return ok ? data : null;
};

//Job

/**
 * Get all job template in current company
 * @param {string} companyID
 * @returns {object[]} list of job template
 */
export const getAllJob = async (companyID) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Company/${companyID}/JobTemplate`,
  );
  return ok ? data : [];
};

/**
 * Create new job template
 * @param {string} companyID
 * @param {object} jobTemplateData
 */
export const createJobTemplate = async (companyID, jobTemplateData) => {
  const { ok, data } = await post(
    `${serverHost}/api/v0/Company/${companyID}/JobTemplate`,
    {
      data: {
        jobTemplateData,
      },
    },
  );
  return ok ? data : null;
};

/**
 * Save job template based on existed one
 * @param {string} companyID
 * @param {string} templateUID
 * @param {object} jobTemplateData job template object
 */
export const updateJobTemplate = async (
  companyID,
  templateUID,
  jobTemplateData,
) => {
  const { ok, data } = await put(
    `${serverHost}/api/v0/Company/${companyID}/JobTemplate/${templateUID}`,
    {
      data: {
        jobTemplateData,
      },
    },
  );
  return ok ? data : null;
};

/**
 * Delete job template
 * @param {string} companyID
 * @param {string} templateUID
 */
export const deleteJobTemplate = async (companyID, templateUID) => {
  const { ok, data } = await del(
    `${serverHost}/api/v0/Company/${companyID}/JobTemplate/${templateUID}`,
  );
  return ok ? data : null;
};

/**
 * Get job template
 * @param {string} companyID
 * @param {string} templateUID
 */
export const getJobTemplate = async (
  companyID,
  templateUID,
  includeCategories,
) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Company/${companyID}/JobTemplate/${templateUID}?includeCategories=${includeCategories}`,
  );
  return ok ? data : null;
};

//Project

/**
 * Create new project template
 * @param {string} companyID
 * @param {object} projectTemplateData  project template object
 */
export const createProjectTemplate = async (companyID, projectTemplateData) => {
  const { ok, data } = await post(
    `${serverHost}/api/v0/Company/${companyID}/ProjectTemplate`,
    {
      data: {
        projectTemplateData,
      },
    },
  );
  return ok ? data : null;
};

/**
 * Save project template based on existed one
 * @param {string} companyID
 * @param {string} templateUID
 * @param {object} projectTemplateData
 */
export const updateProjectTemplate = async (
  companyID,
  templateUID,
  projectTemplateData,
) => {
  const { ok, data } = await put(
    `${serverHost}/api/v0/Company/${companyID}/ProjectTemplate/${templateUID}`,
    {
      data: {
        projectTemplateData,
      },
    },
  );
  return ok ? data : null;
};

/**
 * Get all project template in current company
 * @param {string} companyID
 * @returns {object[]} list of project template
 */
export const getAllProjectTemp = async (companyID) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Company/${companyID}/ProjectTemplate`,
  );
  return ok ? data : [];
};

/**
 * Delete project template
 * @param {string} companyID
 * @param {string} templateUID
 */
export const deleteProjectTemplate = async (companyID, templateUID) => {
  const { ok, data } = await del(
    `${serverHost}/api/v0/Company/${companyID}/ProjectTemplate/${templateUID}`,
  );
  return ok ? data : null;
};

/**
 * Get project template
 * @param {string} companyID
 * @param {string} templateUID
 * @returns {object} get project template
 */
export const getProjectTemplate = async (companyID, templateUID) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Company/${companyID}/ProjectTemplate/${templateUID}`,
  );
  return ok ? data : null;
};
