import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  itemList: {
    height: 250,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  itemContainer: {
    padding: "5px 10px",
  },
  itemAvatarContainer: {
    minWidth: "auto",
  },
  itemSubTitle: {
    "& p": {
      fontSize: 10,
    },
  },
}));

export default useStyles;
