import PropTypes from "prop-types";

export const TableHeadPropTypes = {
  columns: PropTypes.array,
  enableSelect: PropTypes.bool,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  orderableCols: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
};
