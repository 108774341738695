import React, { useEffect, useState } from "react";
import { CFormGroup, CLabel, CInput, CInvalidFeedback } from "@coreui/react";

import { EmailInputPropTypes } from "./EmailInput.types";

// default regex validator
const defaultValidator = (email) => {
  return (
    !!email && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(email)
  );
};

const EmailInput = (props) => {
  const {
    label,
    isRequired,
    id,
    name,
    placeholder,
    initialValue,
    onChange,
    autoComplete,
    disabled,
    validator,
    invalidMessage,
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setInputValue(initialValue || "");
  }, [initialValue]);

  const onChangeHandler = (e) => {
    const value = e.target.value;
    let isDataValid = true;
    if (isRequired) {
      if (validator) {
        isDataValid = validator(value);
      } else {
        isDataValid = defaultValidator(value);
      }
    }
    setIsValid(isDataValid);
    setInputValue(value);
    onChange(name, value, isDataValid);
  };

  return (
    <CFormGroup>
      {label ? (
        <CLabel htmlFor={id}>
          {label} {isRequired ? "*" : ""}
        </CLabel>
      ) : null}
      <CInput
        type="email"
        id={id}
        name={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={onChangeHandler}
        autoComplete={autoComplete}
        disabled={disabled}
        invalid={!isValid}
      />
      {invalidMessage ? (
        <CInvalidFeedback>{invalidMessage}</CInvalidFeedback>
      ) : null}
    </CFormGroup>
  );
};

EmailInput.propTypes = EmailInputPropTypes;

export default EmailInput;
