import PropTypes from "prop-types";

export const JSUTablePropTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.node,
    }),
  ),
  rows: PropTypes.array,
  // table properties
  tableProps: PropTypes.object,
  // enable checkbox
  enableSelect: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  selectedData: PropTypes.array,
  onSelectedData: PropTypes.func,
  // pagination
  enablePagination: PropTypes.bool,
  totalCount: PropTypes.number,
  page: PropTypes.number,
  limit: PropTypes.number,
  pageLimitList: PropTypes.arrayOf(PropTypes.number),
  onPageChange: PropTypes.func,
  onLimitChange: PropTypes.func,
  // sort, disable if sortableCols is empty
  onRequestSort: PropTypes.func,
  orderableCols: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  // custom action buttons
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonProps: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  actionColLabel: PropTypes.string,
};

export const JSUTableDefaultProps = {
  columns: [],
  rows: [],
  tableProps: {},
  enableSelect: false,
  selectedIds: [],
  onSelect: () => void 0,
  seletedData: [],
  onSelectedData: () => void 0,
  enablePagination: true,
  totalCount: 0,
  page: 1,
  limit: 10,
  pageLimitList: [10, 20, 50, 100],
  onPageChange: () => void 0,
  onLimitChange: () => void 0,
  onRequestSort: () => void 0,
  orderableCols: [],
  order: "desc",
  orderBy: null,
  actionButtons: [],
  actionColLabel: "",
};
