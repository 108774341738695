import React, { createContext, useState } from "react";
import {
  parseSummary,
  readGraphical,
  parseGraphical,
} from "~utils/templates/General";

import {
  parseTreeCategory,
  parseNodeData,
  getActivityListFromCategory,
  readCategorySummary,
  parseCategoryTabular,
} from "~utils/templates/Category";
import {
  getActivityTemplate,
  getAllActivity,
  createCategoryTemplate,
  updateCategoryTemplate,
  getAllCategory,
  getCategoryTemplate,
  deleteCategoryTemplate,
  getAllJob,
} from "~apis/template";
import { useContext } from "react";
import { AuthContext } from "..";

export const CompanyCategoryTemplateContext = createContext({});

export const CompanyCategoryTemplateProvider = (props) => {
  const { signedInCompany } = useContext(AuthContext);

  const [templateName, setTemplateName] = useState("");
  const [categoryData, setCategoryData] = useState(null);

  const [categroryOutputTabular, setCategoryOutputTabular] = useState([]);
  const [outputGraphical, setOutputGraphical] = useState([]); //cannot have empty array
  const [outputSummary, setOutputSummary] = useState([]);

  const [activityList, setActivityList] = useState([]);

  const [createdDateTime, setCreatedDateTime] = useState("");
  const [lastModified, setLastModified] = useState("");

  const [templateUID, setTemplateUID] = useState("");
  const [templateList, setTemplateList] = useState([]);

  const [allActivityData, setAllActivityData] = useState([]);
  const [isUsed, setIsUsed] = useState(false);

  const [isValid, setIsValid] = useState({
    templateName: false,
    categoryName: false,
    tableNames: [],
  });

  //Table
  const [pagedList, setPagedList] = useState([]);
  const [totalCount, setTotalCount] = useState();

  //For review
  const [fromProjectCategory, setFromProjectCategory] = useState(false);

  const parseTemplateData = (isEdit) => {
    let templateData = {
      templateName: templateName,
      categoryData: parseTreeCategory(categoryData),
      output: {
        tabular: parseCategoryTabular(categroryOutputTabular),
        graphical: parseGraphical(outputGraphical),
        summary: parseSummary(outputSummary),
      },
      createdDateTime: isEdit ? createdDateTime : new Date().toISOString(),
      lastModified: new Date().toISOString(),
    };

    return templateData;
  };

  const readTemplateData = (data, fromProject) => {
    let tmpdata = data.categoryData;
    parseNodeData(tmpdata, 0, null);

    let tmp_activityList = [];
    tmp_activityList = getActivityListFromCategory(tmpdata);

    if (fromProject) {
      for (let i = 0; i < tmp_activityList.length; i++) {
        for (let j = 0; j < allActivityData.length; j++) {
          if (tmp_activityList[i].id === allActivityData[j].id) {
            tmp_activityList[i].data = allActivityData[j];
          }
        }
      }
    } else {
      for (let i = 0; i < tmp_activityList.length; i++) {
        getActivityTemplate(signedInCompany.id, tmp_activityList[i].id).then(
          (data) => {
            tmp_activityList[i].data = data;
          },
        );
      }
    }

    setTemplateUID(data.id);
    setActivityList(tmp_activityList);
    setTemplateName(data.templateName);
    setCategoryData(tmpdata);
    setIsValid({
      templateName: true,
      tableNames: [],
    });

    setCategoryOutputTabular(data.output.tabular);
    setOutputGraphical(readGraphical(data.output.graphical));
    setOutputSummary(readCategorySummary(data.output.summary));
    setCreatedDateTime(data.createdDateTime);
    setLastModified(data.lastModified);
  };

  const getAllActivityData = (page, limit) => {
    getAllActivity(signedInCompany.id).then((data) => {
      // let tmp_data = data ?? [];
      // setAllActivityData(tmp_data);
      const offset = page * limit;
      const mockPagination = data.slice(offset, offset + limit);
      setPagedList(mockPagination);
      setTotalCount(data.length);
    });
  };

  const saveCategoryTemplateHandler = async () => {
    const templateData = parseTemplateData(false);
    await createCategoryTemplate(signedInCompany.id, templateData);
  };

  const editCategoryTemplateHandler = async () => {
    const templateData = parseTemplateData(false);
    await updateCategoryTemplate(signedInCompany.id, templateUID, templateData);
  };

  const getAllCategoryTemplateHandler = (page, limit) => {
    getAllCategory(signedInCompany.id).then((data) => {
      //setTemplateList(data);

      const offset = page * limit;
      const mockPagination = data.slice(offset, offset + limit);
      setPagedList(mockPagination);
      setTotalCount(data.length);
    });
  };

  const deleteCategoryTemplateHandler = async (id) => {
    await deleteCategoryTemplate(signedInCompany.id, id);
    await getAllCategoryTemplateHandler(0, 100);
  };

  const getCategoryTemplateHandler = (id) => {
    getCategoryTemplate(signedInCompany.id, id).then((data) => {
      readTemplateData(data);
    });
  };

  const checkUsed = async (id) => {
    const jobs = await getAllJob(signedInCompany.id);
    const usedJobs = [];

    jobs.forEach((item) => {
      if (item.categoryTemplates[id]) {
        usedJobs.push(item.jobName);
      }
    });
    return usedJobs;
  };

  return (
    <CompanyCategoryTemplateContext.Provider
      value={{
        templateName,
        setTemplateName,
        categoryData,
        setCategoryData,
        categroryOutputTabular,
        setCategoryOutputTabular,
        outputGraphical,
        setOutputGraphical,
        outputSummary,
        setOutputSummary,
        activityList,
        setActivityList,
        createdDateTime,
        setCreatedDateTime,
        lastModified,
        setLastModified,

        templateUID,
        setTemplateUID,
        templateList,
        setTemplateList,
        allActivityData,
        setAllActivityData,
        isUsed,
        setIsUsed,
        isValid,
        setIsValid,

        parseTemplateData,
        readTemplateData,
        getAllActivityData,
        saveCategoryTemplateHandler,
        editCategoryTemplateHandler,
        getAllCategoryTemplateHandler,
        deleteCategoryTemplateHandler,
        getCategoryTemplateHandler,
        checkUsed,

        //Table
        pagedList,
        setPagedList,
        totalCount,
        setTotalCount,

        //For review
        fromProjectCategory,
        setFromProjectCategory,
      }}
    >
      {props.children}
    </CompanyCategoryTemplateContext.Provider>
  );
};
