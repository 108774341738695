import * as React from "react";

const ProjectTemplateFormPage = React.lazy(() =>
  import("./ProjectTemplateForm"),
);

const ProjectTemplateListPage = React.lazy(() =>
  import("./ProjectTemplateList"),
);

const routePrefix = "/company/template/project";

export const CompanyProjectTemplateRoutes = [
  {
    id: "PROJECTTEPMLATE_LIST",
    exact: true,
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: ProjectTemplateListPage,
    navigation: {
      section: "company",
      navGroup: "Template",
      navTitle: "Project Information",
      permission: "company-template",
    },
  },
  {
    id: "PROJECTTEPMLATE_FORM_CREATE",
    path: `${routePrefix}/create`,
    layoutId: "MainLayout",
    component: ProjectTemplateFormPage,
  },
  {
    id: "PROJECTTEPMLATE_FORM_UPDATE",
    path: `${routePrefix}/:id`,
    layoutId: "MainLayout",
    component: ProjectTemplateFormPage,
  },
];
