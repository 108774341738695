import * as React from "react";

const CompanyUserIndexPage = React.lazy(() => import("./CompanyUser"));

const routePrefix = "/company/user";

export const CompanyUserRoutes = [
  {
    id: "COMPANY_USER",
    exact: true,
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: CompanyUserIndexPage,
    navigation: {
      section: "company",
      navGroup: "Administration",
      navTitle: "Staff",
      permission: "company-user",
    },
  },
];
