const axios = require("axios");

export const call = (method, url, options = {}) => {
  const jwtToken = localStorage.getItem("token") || "";
  const { headers, ...rest } = options;
  const reqOptions = {
    method,
    url,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      ...headers,
    },
    ...rest,
  };

  return axios(reqOptions)
    .then((res) => res.data)
    .catch((error) => {
      if (error.response) {
        // if token expired of is invalid, return to login page
        console.log(error.response);
        if (
          error.response.status === 401 &&
          ["auth-000", "auth-001"].includes(error.response.data.error_code)
        ) {
          localStorage.clear();
          window.location.href = "/login";
        } else if (error.response.data && error.response.data.error_message) {
          alert(`error: ${error.response.data.error_message}`);
        }
      }
      return error;
    });
};

export const get = (url, options) => call("get", url, options);
export const post = (url, options) => call("post", url, options);
export const put = (url, options) => call("put", url, options);
export const del = (url, options) => call("delete", url, options);
