import React, { useState } from "react";
import {
  CCol,
  CLabel,
  CRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdownDivider,
} from "@coreui/react";

import { UnitSelectComponent } from "../UnitSelectComponent";
import { DropdownFieldPropTypes } from "./DropdownField.types";

const DropdownField = (props) => {
  const [fieldValue, setFieldValue] = useState("");
  const [dropdownValues, setDropdownValues] = useState([]);

  const { fieldName, fieldUID, fieldType, unit } = props;

  useState(() => {
    setDropdownValues(props.dropdownValues);
  }, [props.dropdownValues]);

  const setUnitHandler = () => {};

  const dropdownUnitSelectHandler = (value) => {
    setFieldValue(value.dropdownName);
    const [setDataHandler, i] = props.setData;
    setDataHandler(i, {
      id: fieldUID,
      value: value.dropdownValue,
      type: fieldType,
    });
  };

  return (
    <CRow className="g-3 align-items-center">
      <CCol xs="auto">
        <CLabel className="col-form-label">{fieldName}</CLabel>
      </CCol>
      <CCol xs="auto">
        <CDropdown>
          <CDropdownToggle color="secondary">
            {fieldValue !== "" ? fieldValue : "Please Select"}
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem onClick={() => setFieldValue("")}>
              Please Select
            </CDropdownItem>
            <CDropdownDivider />
            {dropdownValues.map((e, i) => (
              <CDropdownItem
                key={`${e.dropdownValue}_${e.dropdownName}_${i}`}
                onClick={() => dropdownUnitSelectHandler(e)}
              >
                {e.dropdownName}
              </CDropdownItem>
            ))}
          </CDropdownMenu>
        </CDropdown>
      </CCol>
      <CCol>
        <UnitSelectComponent setUnitHandler={setUnitHandler} {...unit} />
      </CCol>
    </CRow>
  );
};

DropdownField.propTypes = DropdownFieldPropTypes;

export default DropdownField;
