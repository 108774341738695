import React from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from "@material-ui/core";

import useStyles from "./TableHead.jss";
import { TableHeadPropTypes } from "./TableHead.types";

const JSUTableHead = (props) => {
  const {
    columns,
    enableSelect,
    onRequestSort,
    onSelectAllClick,
    orderableCols,
    order,
    orderBy,
    numSelected,
    rowCount,
    actionButtons,
    actionColLabel,
  } = props;

  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.main}>
      <TableRow>
        {enableSelect && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all",
              }}
            />
          </TableCell>
        )}
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align="left"
            sortDirection={orderBy === column.id ? order : false}
            style={{ width: column.width || null }}
          >
            {orderableCols.includes(column.id) ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
                {orderBy === column.id && (
                  <div className={classes.visuallyHidden}></div>
                )}
              </TableSortLabel>
            ) : (
              <>{column.label}</>
            )}
          </TableCell>
        ))}
        {actionButtons.length > 0 && (
          <TableCell
            align="right"
            style={{ width: 45 * actionButtons.length, minWidth: 100 }}
          >
            {actionColLabel}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

JSUTableHead.propTypes = TableHeadPropTypes;

export default JSUTableHead;
