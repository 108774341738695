import * as React from "react";

const JobFormPage = React.lazy(() => import("./JobFormPage"));

const JobListPage = React.lazy(() => import("./JobListPage"));

const routePrefix = "/company/template/job";

export const CompanyJobRoutes = [
  {
    id: "JOB_LIST",
    exact: true,
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: JobListPage,
    navigation: {
      section: "company",
      navGroup: "Template",
      navTitle: "Data Structure",
      permission: "company-template",
    },
  },
  {
    id: "JOB_FORM_CREATE",
    path: `${routePrefix}/create`,
    layoutId: "MainLayout",
    component: JobFormPage,
  },
  {
    id: "JOB_FORM_UPDATE",
    path: `${routePrefix}/:id`,
    layoutId: "MainLayout",
    component: JobFormPage,
  },
];
