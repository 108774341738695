import React, { useState, useEffect } from "react";
import { CForm, CRow, CCol, CButton, CAlert } from "@coreui/react";

import { EmailInput, TextInput, PasswordInput } from "./components";
import { FormPropTypes } from "./Form.types";

/**
 * Form Component
 *
 * Used to create a form using the fields props, whose types are defined in ./components
 * also need to define the button functions/texts
 */
const Form = (props) => {
  const { fields, onSubmit, submitBtnText, setData } = props;
  const [formData, setFormData] = useState({
    data: {},
    validation: {},
  });
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    let newFormData = {
      data: {},
      validation: {},
    };
    fields.forEach((field) => {
      newFormData.data[field.name] = field.initialValue || "";
      newFormData.validation[field.name] = !(
        field.isRequired && !field.initialValue
      );
    });
    setFormData(newFormData);
    setData(newFormData.data);
  }, [fields]);

  const onInputUpdate = (fieldName, newValue, isValid = true) => {
    setFormData({
      data: { ...formData.data, [fieldName]: newValue },
      validation: { ...formData.validation, [fieldName]: isValid },
    });
    setData({ ...formData.data, [fieldName]: newValue });
  };

  // TODO: support more types number, date, time, file, dropdown, checkbox, ratio
  const renderFormField = (field) => {
    const { type, ...rest } = field;
    switch (type) {
      case "text":
        return <TextInput onChange={onInputUpdate} {...rest} />;
      case "email":
        return <EmailInput onChange={onInputUpdate} {...rest} />;
      case "password":
        return <PasswordInput onChange={onInputUpdate} {...rest} />;
    }
  };

  const onSubmitHandler = () => {
    for (const field of fields) {
      if (!formData.validation[field.name]) {
        setIsInvalid(true);
        return false;
      }
    }
    setIsInvalid(false);
    onSubmit(formData.data);
  };

  // group fields by row
  const fieldsByRows = fields.reduce((acc, obj) => {
    acc[obj.rowNo] = acc[obj.rowNo] ? [...acc[obj.rowNo], obj] : [obj];
    return acc;
  }, {});

  return (
    <div>
      <CForm>
        {Object.keys(fieldsByRows).map((key) => {
          const row = fieldsByRows[key];
          return (
            <CRow key={`form-row-${key}`}>
              {row.map((field) => {
                return (
                  <CCol key={`form-col-${field.id}`} sm={field.colSize || 12}>
                    {renderFormField(field)}
                  </CCol>
                );
              })}
            </CRow>
          );
        })}
      </CForm>
      <div>
        {isInvalid ? (
          <CAlert color="danger">
            Invalid data, please check and submit again
          </CAlert>
        ) : null}
        {submitBtnText ? (
          <CButton color="info" onClick={onSubmitHandler}>
            {submitBtnText}
          </CButton>
        ) : null}
      </div>
    </div>
  );
};

Form.propTypes = FormPropTypes;

export default Form;
