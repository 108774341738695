import React from "react";
import { Avatar } from "@material-ui/core";

import useStyles from "./Avatar.jss";
import { stringAvatar } from "./helper";

const CustomAvatar = (props) => {
  const { name, src, ...rest } = props;
  const classes = useStyles();
  const avatar = stringAvatar(name || "");

  return src ? (
    <Avatar alt={name} src={src} {...rest} />
  ) : (
    <Avatar
      className={classes.avatarSmall}
      style={{ backgroundColor: avatar.bgColor }}
    >
      {avatar.children}
    </Avatar>
  );
};

export default CustomAvatar;
