import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  pageSectionBtn: {
    outline: "none",
    textTransform: "capitalize",
    "&:focus": {
      outline: "none",
    },
  },
  pageSectionItem: {
    textTransform: "capitalize",
  },
  avatarBtn: {
    padding: 0,
  },
  notificationIcon: {
    marginRight: 20,
    cursor: "pointer",
  },
}));

export default useStyles;
