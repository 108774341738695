import * as React from "react";

const CompanyInfoPage = React.lazy(() => import("./CompanyInfo"));

const routePrefix = "/company/current";

export const CompanyInfoRoutes = [
  {
    id: "COMPANY_CURRENT_INFO",
    exact: true,
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: CompanyInfoPage,
  },
];
