import React, { createContext, useState, useEffect } from "react";

export const AppContext = createContext({});

export const AppContextProvider = (props) => {
  const { children } = props;

  const [currentPageSection, setCurrentPageSection] = useState("company");

  useEffect(() => {
    const storedPageSection = localStorage.getItem("pageSection");
    if (storedPageSection) {
      setCurrentPageSection(storedPageSection);
    }
  }, []);

  const handlePageSectionChange = (section) => {
    localStorage.setItem("pageSection", section);
    setCurrentPageSection(section);
  };

  return (
    <AppContext.Provider
      value={{
        currentPageSection,
        handlePageSectionChange,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
