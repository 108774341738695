import PropTypes from "prop-types";

export const TextInputPropTypes = {
  label: PropTypes.node,
  isRequired: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
  validator: PropTypes.func,
  invalidMessage: PropTypes.string,
  disabled: PropTypes.bool,
};
