import React, { useEffect } from "react";
import { CCol, CInput, CLabel, CRow } from "@coreui/react";

import { TextFieldPropTypes } from "./TextField.types";

const TextField = (props) => {
  const { fieldName, fieldUID, fieldType, required, constraints } = props;

  useEffect(() => {}, []);

  const validate = (value) => {
    let isValid = true;
    if (constraints)
      constraints.forEach((e) => {
        const constraintType = e.constraintType;
        const constraintValue = e.constraintValue;
        switch (constraintType) {
          case "Greater Than":
            isValid = value.length > constraintValue;
            break;
          case "Greater Than or Equal To":
            isValid = value.length >= constraintValue;
            break;
          case "Less Than":
            isValid = value.length < constraintValue;
            break;
          case "Less Than or Equal To":
            isValid = value.length >= constraintValue;
            break;
          case "Equals":
            isValid = value.length === constraintValue;
            break;
          default:
            break;
        }
      });
    if (required && value === "") isValid = false;
    const [validateHandler, index] = props.validator;
    validateHandler(index, isValid);
  };

  const onChangeHandler = (event) => {
    validate(event.target.value);
    const [setDataHandler, i] = props.setData;
    setDataHandler(i, {
      id: fieldUID,
      value: event.target.value,
      type: fieldType,
    });
  };

  return (
    <>
      <CRow className="my-2 align-items-center">
        <CCol xs="auto">
          <CLabel htmlFor={fieldUID} className="col-form-label">
            {fieldName}
          </CLabel>
        </CCol>
        <CCol xs="auto">
          <CInput id={fieldUID} onChange={onChangeHandler} />
        </CCol>
      </CRow>
    </>
  );
};

TextField.propTypes = TextFieldPropTypes;

export default TextField;
