import { get, post, del, put } from "~utils/httpClient";
import config from "~config";

const { serverHost } = config;

export const createProject = async (
  companyID,
  parsedProjectInfo,
  projectJobData,
  projectCategoryData,
  projectActivityData,
) => {
  const { ok, data } = await post(
    `${serverHost}/api/v0/Company/${companyID}/Project`,
    {
      data: {
        projectData: parsedProjectInfo,
        jobTemplateData: projectJobData[0],
        categoryTemplateData: projectCategoryData,
        activityTemplateData: projectActivityData,
      },
    },
  );
  return ok ? data : null;
};

/**
 * Get project list
 * @param {string} companyID
 * @returns {object[]} project list
 */
export const getProjectList = async (
  companyID,
  status = "IN_PROGRESS,ARCHIVED",
) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Company/${companyID}/Project?status=${status}`,
  );
  return ok ? data : [];
};

/**
 * get project data
 * @param {string} companyID
 * @param {string} projectID
 * @param {boolean} withTemplate
 * @returns
 */
export const getProject = async (projectID, withTemplate) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Project/${projectID}?includeTemplates=${withTemplate}`,
  );
  return ok ? data : null;
};

/**
 *
 * @param {string} projectID
 * @param {string} activityTemplateID
 * @returns
 */
export const getProjectActivityTemplate = async (
  projectID,
  activityTemplateID,
) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Project/${projectID}/ActivityTemplate/${activityTemplateID}`,
  );
  return ok ? data : null;
};

/**
 *
 * @param {string} projectID
 * @param {string} activityTemplateID
 * @param {object} data
 * @returns
 */
export const updateProjectActivityTemplate = async (
  projectID,
  activityTemplateID,
  data,
) => {
  const { ok } = await put(
    `${serverHost}/api/v0/Project/${projectID}/ActivityTemplate/${activityTemplateID}`,
    { data: data },
  );
  return ok;
};

/**
 *
 * @param {string} projectID
 * @param {string} categoryTemplateID
 * @returns
 */
export const getProjectCategoryTemplate = async (
  projectID,
  categoryTemplateID,
) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Project/${projectID}/CategoryTemplate/${categoryTemplateID}`,
  );
  return ok ? data : null;
};

/**
 *
 * @param {string} projectID
 * @param {string} categoryTemplateID
 * @param {object} data
 * @returns
 */
export const updateProjectCategoryTemplate = async (
  projectID,
  categoryTemplateID,
  data,
) => {
  const { ok } = await put(
    `${serverHost}/api/v0/Project/${projectID}/CategoryTemplate/${categoryTemplateID}`,
    { data: data },
  );
  return ok;
};

/**
 *
 * @param {string} projectID
 * @param {object} data
 * @returns
 */
export const updateProjectJobTemplate = async (projectID, data) => {
  const { ok } = await put(
    `${serverHost}/api/v0/Project/${projectID}/JobTemplate`,
    { data: data },
  );
  return ok;
};

/**
 *
 * @param {string} projectID
 * @param {array} activityTemplateIDs
 * @returns
 */
export const getProjectActivities = async (
  projectID,
  startTime,
  endTime,
  activityTemplateIDs,
  categories,
) => {
  const { ok, data } = await post(
    `${serverHost}/api/v0/Project/${projectID}/getActivityByIds`,
    {
      data: {
        from: startTime,
        to: endTime,
        ids: activityTemplateIDs,
        categories: categories,
      },
    },
  );
  return ok ? data : [];
};

/**
 *
 * @param {string} projectID
 * @param {array} activityTemplateIDs
 * @returns
 */
export const getProjectProblems = async (
  projectID,
  startTime,
  endTime,
  activityTemplateIDs,
  categories,
) => {
  const { ok, data } = await post(
    `${serverHost}/api/v0/Project/${projectID}/getProblemByIds`,
    {
      data: {
        from: startTime,
        to: endTime,
        ids: activityTemplateIDs,
        categories: categories,
      },
    },
  );
  return ok ? data : [];
};

/**
 * Return project user list
 *
 * @param {string} projectId
 * @return {object[]} user list
 */
export const getProjectUserList = async (projectId) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Project/${projectId}/User`,
  );
  return ok ? data : [];
};

/**
 * Update project user role
 *
 * @param {string} projectId
 * @param {string} userId
 * @param {string} newRole
 * @return {object}
 */
export const updateProjectUserRole = async (projectId, userId, newRole) => {
  const { ok, data } = await put(
    `${serverHost}/api/v0/Project/${projectId}/User/${userId}/role`,
    {
      data: { role: newRole },
    },
  );
  return ok ? data : null;
};

/**
 * Add project user
 *
 * @param {string} projectId
 * @param {string} userEmail
 * @param {string} userRole
 * @return {bool}
 */
export const addProjectUser = async (projectId, userEmail, userRole) => {
  const { ok, data } = await post(
    `${serverHost}/api/v0/Project/${projectId}/User`,
    {
      data: {
        role: userRole,
        email: userEmail,
      },
    },
  );
  return ok ? data : null;
};

/**
 * Remove project user
 *
 * @param {string} projectId
 * @param {string} userId
 * @return {bool}
 */
export const removeProjectUser = async (projectId, userId) => {
  const { ok, data } = await del(
    `${serverHost}/api/v0/Project/${projectId}/User/${userId}`,
  );
  return ok ? data : null;
};

/**
 * Return project contractor list
 *
 * @param {string} projectId
 * @return {object[]} contractor list
 */
export const getProjectContractorList = async (projectId) => {
  const { ok, data } = await get(
    `${serverHost}/api/v0/Project/${projectId}/Company`,
  );
  return ok ? data : [];
};

/**
 * Update project user role
 *
 * @param {string} projectId
 * @param {string} companyId
 * @param {string} newRole
 * @return {object}
 */
export const updateProjectCompanyRole = async (
  projectId,
  companyId,
  newRole,
) => {
  const { ok, data } = await put(
    `${serverHost}/api/v0/Project/${projectId}/Company/${companyId}/role`,
    {
      data: { role: newRole },
    },
  );
  return ok ? data : null;
};

/**
 * Add project company
 *
 * @param {string} projectId
 * @param {string} companyName
 * @param {string} role
 * @return {bool}
 */
export const addProjectCompany = async (projectId, companyName, role) => {
  const { ok, data } = await post(
    `${serverHost}/api/v0/Project/${projectId}/Company`,
    {
      data: {
        role: role,
        name: companyName,
      },
    },
  );
  return ok ? data : null;
};

/**
 * Remove project company
 *
 * @param {string} projectId
 * @param {string} companyId
 * @return {bool}
 */
export const removeProjectCompany = async (projectId, companyId) => {
  const { ok, data } = await del(
    `${serverHost}/api/v0/Project/${projectId}/Company/${companyId}`,
  );
  return ok ? data : null;
};

/**
 * Get project raw data
 * @param {string} projectId
 * @param {object} options
 * @returns
 */
export const getProjectRawData = async (projectId, options) => {
  const { ok, data } = await post(
    `${serverHost}/api/v0/Project/${projectId}/getRawData`,
    {
      data: options,
    },
  );
  return ok ? data : {};
};

/**
 * Update activity data
 * @param {string} projectId
 * @param {string} activityId
 * @param {object} newData
 * @returns
 */
export const updateProjectActivity = async (projectId, activityId, newData) => {
  const { ok, data } = await put(
    `${serverHost}/api/v0/Project/${projectId}/Activity/${activityId}`,
    {
      data: {
        activityData: {
          data: newData,
        },
      },
    },
  );
  return ok ? data : null;
};

/**
 * Delete activity data
 * @param {string} projectId
 * @param {string} activityId
 * @returns
 */
export const deleteProjectActivity = async (projectId, activityId) => {
  const { ok, data } = await del(
    `${serverHost}/api/v0/Project/${projectId}/Activity/${activityId}`,
  );
  return ok ? data : null;
};

/**
 *
 * @param {string} companyID
 * @param {string} projectID
 * @param {object} data
 * @returns
 */
export const updateProjectStatus = async (companyID, projectID, data) => {
  const { ok } = await put(
    `${serverHost}/api/v0/Company/${companyID}/Project/${projectID}/status`,
    { data: data },
  );
  return ok;
};
