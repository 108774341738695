import * as React from "react";

const ForgotPasswordPage = React.lazy(() => import("./ForgotPassword"));

const routePrefix = "/reset-password";

export const ForgotPasswordRoutes = [
  {
    id: "FORGOT_PASSWORD_PAGE",
    path: `${routePrefix}`,
    component: ForgotPasswordPage,
  },
];
