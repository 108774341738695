import * as React from "react";

const PrivacyPage = React.lazy(() => import("./Privacy"));

const routePrefix = "/privacy";

export const PrivacyRoutes = [
  {
    id: "PRIVACY_PAGE",
    path: `${routePrefix}`,
    component: PrivacyPage,
  },
];
