import * as React from "react";

const AdminUserIndexPage = React.lazy(() => import("./UserListPage"));
const AdminUserForm = React.lazy(() => import("./UserFormPage"));

const routePrefix = "/admin/user";

export const AdminUserRoutes = [
  {
    id: "ADMIN_USER",
    exact: true,
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: AdminUserIndexPage,
    navigation: {
      section: "admin",
      navGroup: "JSU Management",
      navTitle: "User",
    },
  },
  {
    id: "ADMIN_USER_CREATE",
    path: `${routePrefix}/create`,
    layoutId: "MainLayout",
    component: AdminUserForm,
  },
  {
    id: "ADMIN_USER_UPDATE",
    path: `${routePrefix}/:id`,
    layoutId: "MainLayout",
    component: AdminUserForm,
  },
];
