import * as React from "react";

const ProjectChatPage = React.lazy(() => import("./ProjectChat"));

const routePrefix = "/chat/project";

export const ProjectChatRoutes = [
  {
    id: "PROJECT_CHAT_ROOM",
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: ProjectChatPage,
    navigation: {
      section: "chat",
      navGroup: "Chat",
      navTitle: "Project Chat",
    },
  },
];
