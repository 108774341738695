import React, { createContext, useState, useEffect, useContext } from "react";
import firebase from "firebase";
import get from "lodash/get";

import { AuthContext } from "~contexts";

export const NotificationContext = createContext({});

export const NotificationContextProvider = (props) => {
  const { children } = props;
  const { user } = useContext(AuthContext);

  const [projectChatThreads, setProjectChatThreads] = useState([]);
  const [companyChatThreads, setCompanyChatThreads] = useState([]);
  const [unreadMsgCount, setUnreadMsgCount] = useState({
    project: 0,
    company: 0,
  });

  // listen to the chat thread changes
  useEffect(() => {
    let unsub = () => {};
    if (user) {
      unsub = firebase
        .firestore()
        .collection("chat-threads")
        .where("users", "array-contains", user.id)
        .onSnapshot(
          async (querySnapshot) => {
            const threads = querySnapshot.docs.map((doc) => {
              const thread = doc.data();
              const lastReadCount =
                (thread.userStatus &&
                  thread.userStatus[user.id] &&
                  thread.userStatus[user.id].lastReadCount) ||
                0;
              const unreadCount = thread.totalMsgCount
                ? thread.totalMsgCount - lastReadCount
                : 0;
              const isArchived = get(
                thread,
                `userStatus.${user.id}.isArchived`,
                false,
              );
              return { ...thread, id: doc.id, unreadCount, isArchived };
            });
            const projectThreads = threads.filter((t) => t.type === "project");
            const companyThreads = threads.filter((t) => t.type === "company");
            setProjectChatThreads(projectThreads);
            setCompanyChatThreads(companyThreads);
          },
          (err) => {
            console.log(`Encountered error: ${err}`);
          },
        );
    }
    return () => {
      unsub();
    };
  }, [user]);

  // count total unread messages for project/company
  useEffect(() => {
    let newUnreadMsgCount = {
      project: 0,
      company: 0,
    };
    newUnreadMsgCount.project = projectChatThreads.reduce(
      (p, c) => p + c.unreadCount,
      0,
    );
    newUnreadMsgCount.company = companyChatThreads.reduce(
      (p, c) => p + c.unreadCount,
      0,
    );
    setUnreadMsgCount(newUnreadMsgCount);
  }, [projectChatThreads, companyChatThreads]);

  return (
    <NotificationContext.Provider
      value={{
        projectChatThreads,
        companyChatThreads,
        unreadMsgCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
