/** consts for table head */
export const common = [
  { id: "user", numeric: false, disablePadding: true, label: "User" },
  {
    id: "dateEntry",
    numeric: true,
    disablePadding: false,
    label: "Date of Entry",
  },
  {
    id: "dateActivity",
    numeric: true,
    disablePadding: false,
    label: "Date of Activity",
  },
  { id: "catagory", numeric: true, disablePadding: false, label: "Catagory" },
  { id: "activity", numeric: true, disablePadding: false, label: "Activity" },
  { id: "crewID", numeric: true, disablePadding: false, label: "Crew ID" },
  { id: "numCrew", numeric: true, disablePadding: false, label: "# Crew" },
  { id: "numHours", numeric: true, disablePadding: false, label: "# Hours" },
];

export const headCellValues = {
  ActivityStage: {
    Project: [
      {
        id: "category",
        numeric: false,
        disablePadding: false,
        label: "Category",
      },
      {
        id: "Total Time(Hrs)",
        numeric: true,
        disablePadding: true,
        label: "Total Time(Hrs)",
      },
      {
        id: "Total Time(Days)",
        numeric: true,
        disablePadding: true,
        label: "Total Time(Days)",
      },
      {
        id: "Planned Time(Hrs)",
        numeric: true,
        disablePadding: true,
        label: "Planned Time(Hrs)",
      },
      {
        id: "Planned Time(Days)",
        numeric: true,
        disablePadding: true,
        label: "Planned Time(Days)",
      },
      {
        id: "Built/Planned(%)",
        numeric: true,
        disablePadding: true,
        label: "Built/Planned(%)",
      },
      {
        id: "Relative to Activity Time(%)",
        numeric: true,
        disablePadding: true,
        label: "Relative to Activity Time(%)",
      },
      {
        id: "Relative to Total Project Time(%)",
        numeric: true,
        disablePadding: true,
        label: "Relative to Total Project Time(%)",
      },
      {
        id: "Problem Time (Hrs)",
        numeric: true,
        disablePadding: true,
        label: "Problem Time (Hrs)",
      },
      {
        id: "Problem Time (Days)",
        numeric: true,
        disablePadding: true,
        label: "Problem Time (Days)",
      },
      {
        id: "Prblem/Total(%)",
        numeric: true,
        disablePadding: true,
        label: "Prblem/Total(%)",
      },
    ],
    Category: [
      {
        id: "Activity",
        numeric: false,
        disablePadding: false,
        label: "Activity",
      },
      {
        id: "Completed",
        numeric: true,
        disablePadding: false,
        label: "Completed",
      },
      { id: "Planned", numeric: true, disablePadding: false, label: "Planned" },
      {
        id: "Total Time(Hrs)",
        numeric: true,
        disablePadding: false,
        label: "Total Time(Hrs)",
      },
      {
        id: "Planned Time(Hrs)",
        numeric: true,
        disablePadding: false,
        label: "Planned Time(Hrs)",
      },
      {
        id: "Built/Planned(%)",
        numeric: true,
        disablePadding: false,
        label: "Built/Planned(%)",
      },
      {
        id: "Daily Rate",
        numeric: true,
        disablePadding: false,
        label: "Daily Rate",
      },
      {
        id: "Plan Rate",
        numeric: true,
        disablePadding: false,
        label: "Plan Rate",
      },
      {
        id: "Built/Plan Rate(%)",
        numeric: true,
        disablePadding: false,
        label: "Built/Plan Rate(%)",
      },
      {
        id: "Relative Time",
        numeric: true,
        disablePadding: false,
        label: "Relative Time",
      },
      //{ id: 'Relative to Total Category Time', numeric: true, disablePadding: false, label: 'Relative to Total Category Time' },
    ],
    Activity: {
      All: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        { id: "length", numeric: true, disablePadding: false, label: "Length" },
        { id: "unit", numeric: false, disablePadding: false, label: "Unit" },
        {
          id: "quantity",
          numeric: true,
          disablePadding: false,
          label: "Quantity",
        },
        {
          id: "numPotholes",
          numeric: true,
          disablePadding: false,
          label: "# Potholes",
        },
        {
          id: "numTrips",
          numeric: true,
          disablePadding: false,
          label: "# numTrips",
        },
        {
          id: "dropCount",
          numeric: true,
          disablePadding: false,
          label: "dropCount",
        },
        //change to length
        {
          id: "dropLength",
          numeric: true,
          disablePadding: false,
          label: "dropLength(m)",
        },
        //change to length
        {
          id: "metersPlaced",
          numeric: true,
          disablePadding: false,
          label: "metersPlaced(m)",
        },
        {
          id: "numTerminals",
          numeric: true,
          disablePadding: false,
          label: "numTerminals",
        },
        {
          id: "numFibers",
          numeric: true,
          disablePadding: false,
          label: "numFibers",
        },
        {
          id: "numTesters",
          numeric: true,
          disablePadding: false,
          label: "numTesters",
        },
        //TODO change to fibers?
        {
          id: "numFibersPL",
          numeric: true,
          disablePadding: false,
          label: "numFibersPL",
        },
        {
          id: "testersFHD",
          numeric: false,
          disablePadding: false,
          label: "testersFHD",
        },
        {
          id: "numUnits",
          numeric: true,
          disablePadding: false,
          label: "numUnits",
        },
        { id: "type", numeric: false, disablePadding: false, label: "type" },
        { id: "numWL", numeric: true, disablePadding: false, label: "numWL" },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(m/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(m/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      Drilling: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },
        {
          id: "completion",
          numeric: true,
          disablePadding: false,
          label: "Length",
        },
        { id: "unit", numeric: false, disablePadding: false, label: "Unit" },
        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(m/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(m/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      FP: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        {
          id: "quantity",
          numeric: true,
          disablePadding: false,
          label: "Quantity",
        },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(unit/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(unit/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      Hydrovac: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        {
          id: "numPotholes",
          numeric: true,
          disablePadding: false,
          label: "# Potholes",
        },
        {
          id: "numTrips",
          numeric: true,
          disablePadding: false,
          label: "# numTrips",
        },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(unit/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(unit/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      HandPullingDistributionCable: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        { id: "length", numeric: true, disablePadding: false, label: "length" },
        { id: "unit", numeric: false, disablePadding: false, label: "unit" },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(m/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(m/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      HandPullingDrops: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        {
          id: "dropCount",
          numeric: true,
          disablePadding: false,
          label: "dropCount",
        },
        {
          id: "dropLength",
          numeric: true,
          disablePadding: false,
          label: "dropLength(m)",
        },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(m/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(m/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      HandPullingTerminalTails: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        {
          id: "metersPlaced",
          numeric: true,
          disablePadding: false,
          label: "metersPlaced(m)",
        },
        {
          id: "numTerminals",
          numeric: true,
          disablePadding: false,
          label: "numTerminals",
        },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(m/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(m/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      JettingDistributionCable: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        { id: "length", numeric: true, disablePadding: false, label: "length" },
        { id: "unit", numeric: true, disablePadding: false, label: "unit" },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(m/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(m/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      JettingDrops: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        {
          id: "dropCount",
          numeric: true,
          disablePadding: false,
          label: "dropCount",
        },
        {
          id: "dropLength",
          numeric: true,
          disablePadding: false,
          label: "dropLength(m)",
        },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(m/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(m/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      JettingTerminalTails: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        {
          id: "metersPlaced",
          numeric: true,
          disablePadding: false,
          label: "metersPlaced(m)",
        },
        {
          id: "numTerminals",
          numeric: true,
          disablePadding: false,
          label: "numTerminals",
        },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(m/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(m/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      OTDR: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        {
          id: "numFibers",
          numeric: true,
          disablePadding: false,
          label: "numFibers",
        },
        {
          id: "numTesters",
          numeric: true,
          disablePadding: false,
          label: "numTesters",
        },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(unit/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(unit/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      PowerMeter: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        {
          id: "numFibers",
          numeric: true,
          disablePadding: false,
          label: "numFibers",
        },
        {
          id: "numTerminals",
          numeric: true,
          disablePadding: false,
          label: "numTerminals",
        },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(unit/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(unit/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      RubyRed: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        {
          id: "numFibersPL",
          numeric: true,
          disablePadding: false,
          label: "numFibersPL",
        },
        {
          id: "numTesters",
          numeric: true,
          disablePadding: false,
          label: "numTesters",
        },
        {
          id: "testersFHD",
          numeric: false,
          disablePadding: false,
          label: "testersFHD",
        },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(unit/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(unit/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      SBPlacement: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        {
          id: "numUnits",
          numeric: true,
          disablePadding: false,
          label: "numUnits",
        },
        { id: "type", numeric: false, disablePadding: false, label: "type" },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(unit/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(unit/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
      Splicing: [
        { id: "user", numeric: false, disablePadding: false, label: "User" },
        {
          id: "dateEntry",
          numeric: true,
          disablePadding: false,
          label: "Date of Entry",
        },
        {
          id: "dateActivity",
          numeric: true,
          disablePadding: false,
          label: "Date of Activity",
        },
        {
          id: "catagory",
          numeric: true,
          disablePadding: false,
          label: "Catagory",
        },
        {
          id: "activity",
          numeric: true,
          disablePadding: false,
          label: "Activity",
        },
        {
          id: "crewID",
          numeric: true,
          disablePadding: false,
          label: "Crew ID",
        },
        {
          id: "numCrew",
          numeric: true,
          disablePadding: false,
          label: "# Crew",
        },
        {
          id: "numHours",
          numeric: true,
          disablePadding: false,
          label: "# Hours",
        },

        { id: "numWL", numeric: true, disablePadding: false, label: "numWL" },

        {
          id: "rate",
          numeric: true,
          disablePadding: false,
          label: "Rate(unit/h)",
        },
        {
          id: "ratePerCrew",
          numeric: true,
          disablePadding: false,
          label: "Rate/Crew(unit/h/c)",
        },
        {
          id: "operation",
          numeric: false,
          disablePadding: false,
          label: "Operation",
        },
      ],
    },
  },
  ProblemStage: {
    Activity: [
      { id: "user", numeric: false, disablePadding: false, label: "User" },
      {
        id: "dateEntry",
        numeric: true,
        disablePadding: false,
        label: "Date of Entry",
      },
      {
        id: "dateActivity",
        numeric: true,
        disablePadding: false,
        label: "Date of Activity",
      },
      {
        id: "catagory",
        numeric: true,
        disablePadding: false,
        label: "Catagory",
      },
      {
        id: "activity",
        numeric: true,
        disablePadding: false,
        label: "Activity",
      },
      {
        id: "numHours",
        numeric: true,
        disablePadding: false,
        label: "# Hours",
      },
      {
        id: "issueType",
        numeric: false,
        disablePadding: false,
        label: "Problem Type",
      },
      {
        id: "comments",
        numeric: false,
        disablePadding: false,
        label: "Comments",
      },
      {
        id: "operations",
        numeric: false,
        disablePadding: false,
        label: "Operations",
      },
    ],
  },
};

export const projectItems = [
  "Cabling",
  "Civil",
  "Splicing",
  "Testing",
  "Problems",
];
export const cablingItems = [
  "Hand Pulling/Distribution Cable",
  "Hand Pulling/Drops",
  "Hand Pulling/Terminal Tails",
  "Jetting/Distribution Cable",
  "Jetting/Drops",
  "Jetting/Terminal Tails",
  "Problems",
];
export const civilItems = [
  "Drilling / Conduit Placement",
  "FD / Drop Stubouts",
  "Hydrovac",
  "SB Placement",
  "Problems",
];
export const splicingItems = ["Splicing", "Problems"];
export const testingItems = ["OTDR", "Ruby Red", "Power Meter", "Problems"];

/** consts for editing table */
export const Unit = ["Meter", "Inch", "Foot"];
export const Check = [
  { option: "true", value: true },
  { option: "false", value: false },
];
export const SBType = ["13x24", "2335"];
const issuesList = [
  [
    "Select",
    "Debris clogging issues",
    "Truck maintenance",
    "Wait for directions",
    "Discussion/complaint with resident about work or location",
    "Other",
  ],
  [
    "Select",
    "Soil conditions",
    "Discussion/complaint with resident",
    "Rain/weather conditions impede progress",
    "Equipment failure",
    "Material shortage or wait for delivery",
    "Hit utility line (water, gas, power)",
    "Redo locates",
    "Wait for locates",
    "Other",
  ],
  [
    "Select",
    "Jetting machine broken down",
    "Other equipment failure (not jetting equipment)",
    "Rain/weather conditions impede progress",
    "Civil crew not ready for cable placement",
    "Resident wanted to chat a lot",
    "Discussion or complaint from resident about work/location",
    "Conduit crushed or plugged",
    "Other",
  ],
  [
    "Select",
    "Lost due to rain",
    "Equipment failure",
    "Rain/weather conditions impede progress",
    "Civil crew not ready for cable placement",
    "Resident wanted to chat a lot",
    "Discussion or complaint from resident about work/location",
    "Conduit crushed/plugged",
    "Other",
  ],
  [
    "Select",
    "WL not ready for cable placement",
    "WL filled with water/pumping required (portable pump)",
    "WL filled with water/HVAC pumping required",
    "Missing materials",
    "WL not accessible by truck, tent/table required",
    "Other",
  ],
  [
    "Select",
    "Equipment failure",
    "Splicing not yet complete for WL",
    "No access to CO",
    "Other",
  ],
  [
    "Select",
    "Equipment failure",
    "Splicing not yet complete for WL",
    "Manpower not all ready",
    "No access to CO",
    "Other",
  ],
];

export const issues = {
  Drilling: issuesList[1],
  FP: issuesList[1],
  Hydrovac: issuesList[0],
  HandPullingDistributionCable: issuesList[3],
  HandPullingDrops: issuesList[3],
  HandPullingTerminalTails: issuesList[3],
  JettingDistributionCable: issuesList[2],
  JettingDrops: issuesList[2],
  JettingTerminalTails: issuesList[2],
  OTDR: issuesList[5],
  PowerMeter: issuesList[6],
  RubyRed: issuesList[6],
  SBPlacement: issuesList[1],
  Splicing: issuesList[4],
};

/**
 * Templates
 */
export const constraintAvailable = [
  "number",
  "smallText",
  "longText",
  "datetime",
  "file",
];

export const constraintOptions = {
  number: [
    "",
    "Greater Than",
    "Greater Than or Equal To",
    "Less Than",
    "Less Than or Equal To",
    "Equals",
  ],
  smallText: ["", "Max Length"], //auto set
  longText: ["", "Max Length"],
  datetime: ["", "Greater than", "Less than"],
  file: ["", "Max File Size", "Allowed Extensions"],
};

export const templateHeadCells = {
  activity: [
    { id: "index", label: "ID", sortable: true },
    { id: "name", label: "Activity Name", sortable: true },
    { id: "createdDateTime", label: "Create Time", sortable: true },
    { id: "lastModified", label: "Last Modified", sortable: true },
    { id: "buttons", label: " " },
  ],
  category: [
    { id: "index", label: "ID", sortable: true },
    { id: "templateName", label: "Category Name", sortable: true },
    { id: "createdDateTime", label: "Create Time", sortable: true },
    { id: "lastModified", label: "Last Modified", sortable: true },
    { id: "buttons", label: " " },
  ],
  job: [
    { id: "index", label: "ID", sortable: true },
    { id: "jobName", label: "Job Name", sortable: true },
    { id: "createdDateTime", label: "Create Time", sortable: true },
    { id: "lastModified", label: "Last Modified", sortable: true },
    { id: "buttons", label: " " },
  ],
  project: [
    { id: "index", label: "ID", sortable: true },
    { id: "templateName", label: "Project Name", sortable: true },
    { id: "createdDateTime", label: "Create Time", sortable: true },
    { id: "lastModified", label: "Last Modified", sortable: true },
    { id: "buttons", label: " " },
  ],
  assignActivity: [
    { id: "checkbox", label: " " },
    { id: "name", label: "Activity Name", sortable: true },
    { id: "createdDateTime", label: "Create Time", sortable: true },
  ],
  assignCategory: [
    { id: "checkbox", label: " " },
    { id: "templateName", label: "Category Name", sortable: true },
    { id: "createdDateTime", label: "Create Time", sortable: true },
  ],
};

export const templateDisplayKey = {
  activity: [
    { keyName: "index", type: "index" },
    { keyName: "name", type: "string" },
    { keyName: "createdDateTime", type: "datetime" },
    { keyName: "lastModified", type: "datetime" },
    { keyName: "none", type: "buttons" },
  ],
  category: [
    { keyName: "index", type: "index" },
    { keyName: "templateName", type: "string" },
    { keyName: "createdDateTime", type: "datetime" },
    { keyName: "lastModified", type: "datetime" },
    { keyName: "none", type: "buttons" },
  ],
  job: [
    { keyName: "index", type: "index" },
    { keyName: "jobName", type: "string" },
    { keyName: "createdDateTime", type: "datetime" },
    { keyName: "lastModified", type: "datetime" },
    { keyName: "none", type: "buttons" },
  ],
  project: [
    { keyName: "index", type: "index" },
    { keyName: "templateName", type: "string" },
    { keyName: "createdDateTime", type: "datetime" },
    { keyName: "lastModified", type: "datetime" },
    { keyName: "none", type: "buttons" },
  ],
  assignActivity: [
    { keyName: "checkbox", type: "checkbox" },
    { keyName: "name", type: "string" },
    { keyName: "createdDateTime", type: "datetime" },
  ],
  assignCategory: [
    { keyName: "checkbox", type: "checkbox" },
    { keyName: "templateName", type: "string" },
    { keyName: "createdDateTime", type: "datetime" },
  ],
};

export const tableHeadCells = {
  project: [
    { id: "index", label: "ID", sortable: true },
    { id: "name", label: "Project Name", sortable: true },
    { id: "start", label: "Start Time", sortable: true },
    { id: "end", label: "End Time", sortable: true },
    { id: "lastModified", label: "Last Modified", sortable: true },
    { id: "buttons", label: " " },
  ],
  company: [
    { id: "index", label: "ID", sortable: true },
    { id: "name", label: "Company Name", sortable: true },
    { id: "lastModified", label: "Last Modified", sortable: true },
    { id: "buttons", label: " " },
  ],
};

export const tableDisplayKey = {
  project: [
    { keyName: "index", type: "index" },
    { keyName: "projectName", type: "string" },
    { keyName: "start", type: "datetime" },
    { keyName: "end", type: "datetime" },
    { keyName: "lastModified", type: "datetime" },
    { keyName: "none", type: "buttons" },
  ],
  company: [
    { keyName: "index", type: "index" },
    { keyName: "name", type: "string" },
    { keyName: "lastModified", type: "datetime" },
    { keyName: "none", type: "buttons" },
  ],
};
