import React from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Button,
} from "@material-ui/core";
import moment from "moment";

import * as JSUButton from "~components";
import { TableBodyPropTypes } from "./TableBody.types";

// TODO: add custom row rendering, which allows to define custom row/cell in pages
const JSUTableBody = (props) => {
  const {
    columns,
    rows,
    enableSelect,
    selectedIds,
    order,
    orderBy,
    onSelectRow,
    actionButtons,
  } = props;

  const getCellDisplay = (row, column) => {
    if (column.type === "datetime") {
      return moment(row[column.id]).format("YYYY-MM-DD HH:mm:ss");
    }
    return row[column.id] && row[column.id].toString();
  };

  return (
    <TableBody>
      {rows
        .sort((a, b) => {
          if (!orderBy) return 0;
          const aVal =
            typeof a[orderBy] === "string"
              ? a[orderBy].toLowerCase()
              : a[orderBy];
          const bVal =
            typeof b[orderBy] === "string"
              ? b[orderBy].toLowerCase()
              : b[orderBy];
          if (aVal < bVal) {
            return order === "asc" ? -1 : 1;
          }
          if (aVal > bVal) {
            return order === "asc" ? 1 : -1;
          }
          return 0;
        })
        .map((row, rowIndex) => {
          const isSelected = selectedIds ? selectedIds.includes(row.id) : false;
          const labelId = `table-checkbox-${row.id}`;

          return (
            <TableRow
              hover
              tabIndex={-1}
              key={`table-row-${row.id}-${rowIndex}`}
            >
              {enableSelect && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                    onChange={() => onSelectRow(row.id, row)}
                  />
                </TableCell>
              )}
              {columns.map((column) => {
                return (
                  <TableCell
                    key={`table-row-${column.id}-${row.id}`}
                    align="left"
                  >
                    {getCellDisplay(row, column)}
                  </TableCell>
                );
              })}
              {actionButtons.length > 0 && (
                <TableCell
                  key={`table-row-actions-${row.id}-${rowIndex}`}
                  align="right"
                >
                  {!row.disableActionButtons &&
                    actionButtons.map((button, index) => {
                      const { buttonProps, label, icon, onClick } = button;
                      const CustomButton = icon ? JSUButton[icon] : null;
                      return label ? (
                        <Button
                          // eslint-disable-next-line
                          key={`table-row-actions-${row.id}-button-${index}`}
                          {...buttonProps}
                          onClick={(e) => onClick(row, e)}
                        >
                          {label}
                        </Button>
                      ) : (
                        <CustomButton
                          // eslint-disable-next-line
                          key={`table-row-actions-${row.id}-button-${index}`}
                          {...buttonProps}
                          onClick={(e) => onClick(row, e)}
                        />
                      );
                    })}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      {rows.length === 0 && (
        <TableRow>
          <TableCell colSpan={columns.length + 1}>No Data Available</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

JSUTableBody.propTypes = TableBodyPropTypes;

export default JSUTableBody;
