import * as React from "react";

import { AdminCompanyRoutes } from "./AdminCompany";
import { AdminUserRoutes } from "./AdminUser";

const AdminIndex = React.lazy(() => import("./Admin"));
const routePrefix = "/admin";

// all routes for sub pages
export const adminPageRoutes = [...AdminCompanyRoutes, ...AdminUserRoutes];

// default redirect to this path
export const homePagePath = `${routePrefix}/company`;

// used for sidebar rendering by defined navigation
export const adminSidebarNavs = adminPageRoutes.filter(
  (route) => !!route.navigation,
);

// used for Router rendering
export const AdminRoutes = [
  {
    id: "ADMIN_INDEX",
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: AdminIndex,
  },
];
