import * as React from "react";

const RegistrationPage = React.lazy(() => import("./Registration"));

const routePrefix = "/register";

export const RegistrationRoutes = [
  {
    id: "REGISTRATION_PAGE",
    path: `${routePrefix}`,
    component: RegistrationPage,
  },
];
