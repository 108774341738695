import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  cardTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  regionCard: {
    marginTop: "1em",
  },
});

export default useStyles;
