import firebase from "firebase";

import { get, post } from "~utils/httpClient";
import config from "~config";

const { serverHost } = config;

/**
 * Login
 *
 * @param {string} email account email
 * @param {string} password account password
 * @return {object} JWT token & userId
 */
export const login = async (email, password) => {
  const userCredential = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  if (!userCredential.user) {
    throw Error("login failed");
  }
  return userCredential.user;
};

/**
 * Logout
 */
export const logout = async () => {
  await firebase.auth().signOut();
};

/**
 * Signin a company
 *
 * @param {string} companyId company ID
 * @param {string} userId user ID
 * @return {void}
 */
export const signInCompany = async (companyId, userId) => {
  const { ok, data } = await post(`${serverHost}/api/v0/Auth/signInCompany`, {
    data: {
      companyUID: companyId,
      userUID: userId,
    },
  });
  return ok ? data : null;
};

export const getAuthedCompanyList = async () => {
  const { ok, data } = await get(`${serverHost}/api/v0/User/Company`);
  return ok ? data : [];
};

/**
 * Reset password by sending email
 */
export const resetPassword = async (email) => {
  return await firebase.auth().sendPasswordResetEmail(email);
};

/**
 * Verify the user's token
 */
export const verifyToken = async () => {
  const { ok } = await get(`${serverHost}/api/v0/Auth/verifyToken`);
  return ok;
};
