import * as React from "react";

const CompanyProjectIndexPage = React.lazy(() => import("./ProjectListPage"));
const CompanyProjectForm = React.lazy(() => import("./ProjectFormPage"));

const routePrefix = "/company/project";

export const CompanyProjectRoutes = [
  {
    id: "COMPANY_PROJECT",
    exact: true,
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: CompanyProjectIndexPage,
    navigation: {
      section: "project",
      navGroup: "Project",
      navTitle: "Project List",
      permission: "company-project",
    },
  },
  {
    id: "COMPANY_PROJECT_CREATE",
    path: `${routePrefix}/create`,
    layoutId: "MainLayout",
    component: CompanyProjectForm,
  },
  {
    id: "COMPANY_PROJECT_UPDATE",
    path: `${routePrefix}/:id`,
    layoutId: "MainLayout",
    component: CompanyProjectForm,
  },
];
