import * as React from "react";

const TestPage = React.lazy(() => import("./Test"));

const routePrefix = "/test";

export const TestRoutes = [
  {
    id: "LOGIN_PAGE",
    path: `${routePrefix}`,
    component: TestPage,
  },
];
