import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  copyRightFooter: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    padding: "5px 10px",
    fontSize: 12,
  },
});

export default useStyles;
