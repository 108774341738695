// Category Template helper function

//Parse category data to back-end format
export function parseTreeCategory(nodes) {
  let tmp_node = nodes;

  function readTree(nodes) {
    delete nodes.id;
    delete nodes.parentId;
    delete nodes.isEndNode;
    nodes.categoryName = nodes.name;
    delete nodes.name;

    if (nodes.activities) {
      let tmp_ac = {};
      nodes.activities.map((item) => {
        let keyName = item.name.replace(/\s*/g, "");
        tmp_ac = {
          ...tmp_ac,
          [keyName]: {
            activityName: item.name,
            activityUID: item.id,
          },
        };
      });

      nodes.activities = tmp_ac;
    }

    if (nodes.subCategories) {
      //Remove button
      nodes.subCategories.pop();
      let subCategories = {};

      for (let i = 0; i < nodes.subCategories.length; i++) {
        let keyName = nodes.subCategories[i].name.replace(/\s*/g, "");
        subCategories = {
          ...subCategories,
          [keyName]: nodes.subCategories[i],
        };
      }
      nodes.subCategories = subCategories;

      Object.keys(nodes.subCategories).forEach(function (key) {
        if (!nodes.subCategories[key].isEndNode) {
          return readTree(nodes.subCategories[key]);
        } else {
          return;
        }
      });
    }
    return;
  }

  readTree(tmp_node);

  return tmp_node;
}

//Read category data and parse to front-end format
export function parseNodeData(nodes, index, parent) {
  if (nodes.name !== "Add") {
    nodes.id = String(index);
    nodes.name = nodes.categoryName;
    nodes.parentId = parent;
    nodes.isEndNode = false;
    if (nodes.activities) {
      let tmp = [];
      Object.keys(nodes.activities).forEach(function (key) {
        tmp.push({
          name:
            nodes.activities[key].activityName || nodes.activities[key].name,
          id: nodes.activities[key].activityUID || nodes.activities[key].id,
        });
      });
      nodes.activities = tmp;
    }

    if (nodes.subCategories) {
      //Object to array
      let tmp = [];
      Object.keys(nodes.subCategories).forEach(function (key) {
        tmp.push(nodes.subCategories[key]);
      });
      nodes.isEndNode = false;
      tmp.push({
        id: nodes.id + "_add",
        parentId: nodes.id,
        isEndNode: true,
        name: "Add",
      });
      nodes.subCategories = tmp;
      index += 1;
      return nodes.subCategories.map((node, key) =>
        parseNodeData(node, index + key, nodes.id),
      );
    } else {
      nodes.subCategories = [
        {
          id: index + "_add",
          parentId: String(index),
          isEndNode: true,
          name: "Add",
        },
      ];
      index += 1;
      return;
    }
  } else {
    return;
  }
}

/**
 * read through the category tree and save all the activities in activityList
 * used as options in the output section
 * @param {*} nodes
 * @returns activityList
 */
export function getActivityListFromCategory(nodes) {
  function readTree(nodes, activityList = [], stack = []) {
    if (nodes.activities) {
      stack.push(nodes.name);
      let copiedStack = stack.slice(0);
      let display = "";
      for (let i = 1; i < copiedStack.length; i++) {
        display += copiedStack[i] + "-";
      }
      nodes.activities.map((item) => {
        activityList.push({
          ...item,
          category: [...copiedStack.slice(1, copiedStack.length), item.name],
          displayName: display + item.name,
        });
      });
    }
    if (nodes.subCategories) {
      return nodes.subCategories.map((node) =>
        readTree(node, activityList, stack),
      );
    }
    stack.pop();
    return;
  }
  let activityList = new Array();
  let stack = new Array();
  readTree(nodes, activityList, stack);

  return activityList;
}

/** Category Output */
// Parse the category table to back-end format
export function parseCategoryTabular(table) {
  const tmp_table = table;

  for (let i = 0; i < tmp_table.length; i++) {
    delete tmp_table[i].id;
  }
  return tmp_table;
}

//Parse the category table to front-end format
export function readCategoryTabular(table) {
  let tmp_table = table.map((titem, key) => {
    let tmpTableColumns = titem.tableColumns.map((item, key) => {
      let tmp_summaryFieldValues = [];
      if (item.columnType === "activitySummaryField") {
        Object.keys(item.summaryFieldValues).forEach(function (key) {
          tmp_summaryFieldValues.push({
            ...item.summaryFieldValues[key],
            summaryActivityID: key,
          });
        });
        tmp_summaryFieldValues.forEach((vitem, index, arr) => {
          let tmp_category = [];
          Object.keys(vitem.category).forEach(function (key) {
            tmp_category.push(vitem.category[key]);
          });
          arr[index].category = tmp_category;
        });
      }
      return {
        id: key,
        columnName: item.columnName,
        columnID: item.columnID,
        columnType: item.columnType,
        summaryFieldValues: tmp_summaryFieldValues,
      };
    });

    return {
      ...titem,
      id: key,
      tableColumns: tmpTableColumns,
    };
  });
  return tmp_table;
}

export function readCategoryGraph(data) {
  return Object.keys(data).map((key) => {
    return {
      ...data[key],
    };
  });
}

//read the category table and parse to front-end format
export function readCategorySummary(data) {
  let tmp_summary = [];
  let index = 0;
  Object.keys(data).forEach(function (key) {
    tmp_summary.push({
      id: index,
      ...data[key],
    });
    index += 1;
  });
  return tmp_summary;
}

//Not used
/**
 * use projectActivityData and projectCategoryData to check if
 * there is conflict in output
 * @param {Array} activityList from project
 * @param {Array} categories
 * @returns
 */
export function checkCategory(activityList, categories) {
  let tmpAct = activityList.map((item) => {
    return {
      name: item.name,
      summary: item.output.activityOutput.summary,
    };
  });
  let tmpProb = activityList.map((item) => {
    return {
      name: item.name + "-problem",
      summary: item.output.problemOutput.summary,
    };
  });

  const activitySummaryList = tmpAct.concat(tmpProb);

  let res = [];
  categories.map((citem) => {
    citem.output.tabular.map((titem) => {
      titem.tableColumns.map((column) => {
        if (column.columnType === "activitySummaryField") {
          Object.keys(column.summaryFieldValues).forEach(function (key) {
            const name = key.split("-").pop();
            const value = column.summaryFieldValues[key].summaryFieldID;
            console.log("!!", name, value);
            let valid = false;
            for (let i = 0; i < activitySummaryList.length; i++) {
              if (activitySummaryList[i].name === name) {
                if (activitySummaryList[i].summary[value]) {
                  valid = true;
                  break;
                }
              }
            }
            if (!valid) {
              res.push({ name: citem.templateName, column: column.columnName });
            }
          });
        }
      });
    });
  });
  console.log("res", res);
  return res;
}

/**
 * get the options for category table
 * @param {*} activityList
 * @returns
 */
export const getCategoryTableOption = (activityList) => {
  let options = activityList
    .filter((item) => !!item.data)
    .map((item) => {
      let tmp_activity = [];
      Object.keys(item.data.output.activityOutput.summary).forEach(function (
        key,
      ) {
        tmp_activity.push(item.data.output.activityOutput.summary[key]);
      });

      let tmp_problem = [];
      Object.keys(item.data.output.problemOutput.summary).forEach(function (
        key,
      ) {
        tmp_problem.push(item.data.output.problemOutput.summary[key]);
      });

      return {
        name: item.displayName,
        category: item.category,
        fields: tmp_activity,
        problemFields: tmp_problem,
      };
    });
  // let problemSummaryOptions = activityList.map((item) => {
  //   let tmp = [];
  //   Object.keys(item.data.output.problemOutput.summary).forEach(function (key) {
  //     tmp.push(item.data.output.problemOutput.summary[key]);
  //   });
  //   return {
  //     name: item.displayName + "-problem",
  //     category: item.category,
  //     fields: tmp,
  //   };
  // });
  // let res = activitySummaryOptions.concat(problemSummaryOptions);
  return options;
};
