import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CHeader, CToggler, CHeaderNav } from "@coreui/react";
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Grid,
  Badge,
  IconButton,
} from "@material-ui/core";
import {
  PowerSettingsNew,
  AccountCircle,
  Business,
  ArrowDropDown,
  Message as MessageIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@material-ui/icons";

import { Avatar } from "~components";
import { AppContext, AuthContext, NotificationContext } from "~contexts";
import useStyles from "./Header.jss";

const pageSectionsByRole = {
  Admin: ["company", "project", "chat", "admin"],
  Basic: ["company", "project", "chat"],
};

const TheHeader = (props) => {
  const { toggleSidebar } = props;

  const [pageSections, setPageSections] = useState([]);
  const [pageSectionAnchorEl, setPageSectionAnchorEl] = useState(null);
  const [switchCompanyAnchorEl, setSwitchCompanyAnchorEl] = useState(null);
  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const { currentPageSection, handlePageSectionChange } =
    useContext(AppContext);
  const { user, logout, signedInCompany, authedCompanyList, signInCompany } =
    useContext(AuthContext);
  const { unreadMsgCount } = useContext(NotificationContext);

  const [showNotificationReminder, setShowNotificationReminder] =
    useState(false);

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      setPageSections(pageSectionsByRole[user.accountType]);
    }
  }, [user]);

  useEffect(() => {
    for (let k in unreadMsgCount) {
      if (unreadMsgCount[k] > 0) {
        setShowNotificationReminder(true);
        return;
      }
    }
    setShowNotificationReminder(false);
  }, [unreadMsgCount]);

  const handleProfile = async () => {
    history.push("/profile");
    setAvatarAnchorEl(null);
  };

  const handleSwitchCompany = async (company) => {
    setSwitchCompanyAnchorEl(null);
    await signInCompany(company);
    window.location.reload();
  };

  const handleLogout = async () => {
    await logout();
    history.push("/login");
  };

  const onPageSectionChange = (section) => {
    handlePageSectionChange(section);
    setPageSectionAnchorEl(null);
    history.push(`/${section}`);
  };

  const renderPageSectionMenu = () => {
    return (
      <div>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          disableTouchRipple={true}
          disableFocusRipple={true}
          className={classes.pageSectionBtn}
          onClick={(e) => setPageSectionAnchorEl(e.currentTarget)}
        >
          {currentPageSection} <ArrowDropDown />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={pageSectionAnchorEl}
          keepMounted
          open={Boolean(pageSectionAnchorEl)}
          onClose={() => setPageSectionAnchorEl(null)}
        >
          {pageSections.map((section) => {
            return (
              <MenuItem
                key={`page-section-nav-${section}`}
                className={classes.pageSectionItem}
                disabled={section === currentPageSection}
                onClick={() => onPageSectionChange(section)}
              >
                {section}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  };

  // TODO: replace the Chip with a select, allow user to switch company anytime
  const renderAvatarMenu = () => {
    return (
      <div>
        <Badge
          color="secondary"
          variant="dot"
          invisible={!showNotificationReminder}
          className={classes.notificationIcon}
          onClick={() => onPageSectionChange("chat")}
        >
          <MessageIcon />
        </Badge>
        <Chip
          icon={<Business />}
          label={signedInCompany ? signedInCompany.name : ""}
          variant="outlined"
          style={{ marginRight: 10, padding: 5 }}
          deleteIcon={authedCompanyList.length > 1 && <ArrowDropDownIcon />}
          onDelete={
            authedCompanyList.length > 1 &&
            ((e) => setSwitchCompanyAnchorEl(e.target.parentNode))
          }
        />
        <Menu
          id="company-menu"
          anchorEl={switchCompanyAnchorEl}
          keepMounted
          open={Boolean(switchCompanyAnchorEl)}
          onClose={() => setSwitchCompanyAnchorEl(null)}
          PaperProps={{
            style: { maxHeight: 200 },
          }}
        >
          {authedCompanyList.map((c) => (
            <MenuItem
              key={c.id}
              disabled={c.id === signedInCompany.id}
              onClick={() => handleSwitchCompany(c)}
            >
              {c.name}
            </MenuItem>
          ))}
        </Menu>
        <IconButton
          aria-controls="avatar-menu"
          className={classes.avatarBtn}
          onClick={(e) => setAvatarAnchorEl(e.currentTarget)}
        >
          {user && <Avatar name={user.name} src={user.avatarUrl} />}
        </IconButton>
        <Menu
          id="avatar-menu"
          anchorEl={avatarAnchorEl}
          keepMounted
          open={Boolean(avatarAnchorEl)}
          onClose={() => setAvatarAnchorEl(null)}
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleProfile}>
            <ListItemIcon>
              <AccountCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <PowerSettingsNew fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <CHeader withSubheader>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div>
          <Grid container alignItems="center">
            <CToggler
              inHeader
              className="ml-3 d-md-down-none"
              onClick={toggleSidebar}
            />
            {renderPageSectionMenu()}
          </Grid>
        </div>
        <CHeaderNav className="px-3">{renderAvatarMenu()}</CHeaderNav>
      </Grid>
    </CHeader>
  );
};

export default TheHeader;
