import React from "react";
import { Button, Paper, InputBase, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import {
  PageHeaderPropTypes,
  PageHeaderDefaultProps,
} from "./PageHeader.types";
import useStyles from "./PageHeader.jss";

/**
 * The page header with title and action area
 */
const PageHeader = (props) => {
  const {
    title,
    hasSearch,
    onSearch,
    hasAdd,
    onAdd,
    hasAddIcon,
    addBtnText,
    children: customActions,
    customLeft,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.actionArea}>
        {customLeft}
        {hasSearch && (
          <Paper component="form" className={classes.searchWrapper}>
            <InputBase
              className={classes.searchInput}
              placeholder="Search..."
            />
            <IconButton className={classes.iconButton} onClick={onSearch}>
              <SearchIcon />
            </IconButton>
          </Paper>
        )}
        {hasAdd && (
          <Button
            variant="contained"
            className={classes.addBtn}
            startIcon={hasAddIcon && <AddIcon />}
            onClick={onAdd}
          >
            {addBtnText}
          </Button>
        )}
        {customActions}
      </div>
    </div>
  );
};

PageHeader.prototype = PageHeaderPropTypes;
PageHeader.defaultProps = PageHeaderDefaultProps;
export default PageHeader;
