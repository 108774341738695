import React, { createContext, useState } from "react";
import { useContext } from "react";
import { cloneDeep } from "lodash";

import { createProject, getProjectList, getProject } from "~apis/project";
import { parseNodeData } from "~utils/templates/Category";
import { AuthContext } from "..";
export const CompanyProjectContext = createContext({});

export const CompanyProjectProvider = (props) => {
  const { signedInCompany } = useContext(AuthContext);

  const [projectName, setProjectName] = useState("");
  const [projectError, setProjectError] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [projectInfo, setProjectInfo] = useState({});
  const [projectUserInfo, setProjectUserInfo] = useState([]);
  const [projectJobData, setProjectJobData] = useState([]);
  const [projectCategoryData, setProjectCategoryData] = useState([]);
  const [projectActivityData, setProjectActivityData] = useState([]);

  const [projectCategoryDataDisplay, setProjectCategoryDataDisplay] = useState(
    [],
  );

  const [projectTemplateList, setProjectTemplateList] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState({});

  const [validWarning, setValidWarning] = useState([]);
  const [errorStage, setErrorStage] = useState(-1);

  const [pagedList, setPagedList] = useState([]);
  const [totalCount, setTotalCount] = useState([]);

  const saveProjectTemplateHandler = async () => {
    let parsedProjectInfo = {};
    for (let key of Object.keys(projectInfo)) {
      let id = projectInfo[key].id;
      let value = projectInfo[key].value;
      if (id && value) {
        parsedProjectInfo[id] = value;
      }
    }
    parsedProjectInfo.projectName = projectName;
    for (let key of Object.keys(parsedProjectInfo)) {
      if (!parsedProjectInfo[key]) {
        alert(`Empty Field: ${key}`);
        return;
      }
    }

    const projectID = await createProject(
      signedInCompany.id,
      parsedProjectInfo,
      projectJobData,
      projectCategoryData,
      projectActivityData,
    );
    return projectID;
  };

  const getAllProjectHandler = (page, limit) => {
    getProjectList(signedInCompany.id).then((data) => {
      const offset = page * limit;
      const mockPagination = data.slice(offset, offset + limit);
      setPagedList(mockPagination);
      setTotalCount(data.length);
    });
  };

  const getProjectHandler = (projectID, withTemplate) => {
    getProject(projectID, withTemplate).then((data) => {
      const activities = data.activities;
      setProjectActivityData(activities);

      delete data.activities;
      const categories = cloneDeep(data.categories);
      setProjectCategoryData(categories);

      let tmp = Object.assign([], data.categories).map((item) => {
        let tmp_cData = item.categoryData;
        parseNodeData(tmp_cData, 0, null);
        return {
          id: item.id,
          categoryData: tmp_cData,
          output: item.output,
          templateName: item.templateName,
        };
      });
      setProjectCategoryDataDisplay(tmp);
      delete data.categories;
      const jobs = data.jobs;
      setProjectJobData(jobs);
      delete data.jobs;
      setProjectName(data.projectName);
      delete data.projectName;
      delete data.id;
      delete data.createDatetime;
      delete data.updateDatetime;
      delete data.users;
      setProjectInfo(data);
    });
  };

  return (
    <CompanyProjectContext.Provider
      value={{
        projectName,
        setProjectName,
        projectError,
        setProjectError,
        isValid,
        setIsValid,
        validWarning,
        setValidWarning,
        errorStage,
        setErrorStage,
        projectUserInfo,
        setProjectUserInfo,
        projectInfo,
        setProjectInfo,
        projectJobData,
        setProjectJobData,
        projectCategoryData,
        setProjectCategoryData,
        projectActivityData,
        setProjectActivityData,
        projectCategoryDataDisplay,
        setProjectCategoryDataDisplay,

        projectTemplateList,
        setProjectTemplateList,
        currentTemplate,
        setCurrentTemplate,

        pagedList,
        setPagedList,
        totalCount,
        setTotalCount,

        saveProjectTemplateHandler,
        getAllProjectHandler,
        getProjectHandler,
      }}
    >
      {props.children}
    </CompanyProjectContext.Provider>
  );
};
