import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 80,
    background: "white",
    borderBottom: "2px solid #ebedef",
    padding: 10,
  },
  title: {
    textTransform: "capitalize",
  },
  actionArea: {
    display: "flex",
    flexDirection: "row",
    columnGap: 10,
    padding: 10,
    height: 60,
  },
  addBtn: {
    padding: "5px 15px",
    color: "white",
    backgroundColor: "#39f",
    "&:hover": {
      backgroundColor: "#39f",
      boxShadow: "none",
    },
  },
  searchWrapper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 250,
    border: "1px solid #ccc",
    boxShadow: "none",
  },
  searchInput: {
    flex: 1,
    padding: 10,
  },
  iconButton: {
    padding: 10,
  },
});

export default useStyles;
