import React, { useEffect } from "react";
import { CCol, CInput, CLabel, CRow } from "@coreui/react";

import { UnitSelectComponent } from "../UnitSelectComponent";
import { NumberFieldPropTypes } from "./NumberField.types";

const NumberField = (props) => {
  const {
    fieldName,
    fieldUID,
    fieldType,
    acceptDecimal,
    required,
    unit,
    constraints,
  } = props;

  useEffect(() => {}, []);

  const validate = (value) => {
    let isValid = true;
    if (constraints)
      constraints.forEach((e) => {
        const constraintType = e.constraintType;
        const constraintValue = e.constraintValue;
        switch (constraintType) {
          case "Greater Than":
            isValid = value > constraintValue;
            break;
          case "Greater Than or Equal To":
            isValid = value >= constraintValue;
            break;
          case "Less Than":
            isValid = value < constraintValue;
            break;
          case "Less Than or Equal To":
            isValid = value >= constraintValue;
            break;
          case "Equals":
            isValid = value === constraintValue;
            break;
          default:
            break;
        }
      });
    if (required && value === "") isValid = false;
    const [validateHandler, index] = props.validator;
    validateHandler(index, isValid);
  };

  const setUnitHandler = () => {};

  const onChangeHandler = (event) => {
    validate(event.target.value);
    const [setDataHandler, i] = props.setData;
    setDataHandler(i, {
      id: fieldUID,
      value: event.target.value,
      type: fieldType,
    });
  };

  const keyDownHandler = (event) => {
    if (!acceptDecimal && event.key === ".") event.preventDefault();
  };

  return (
    <>
      <CRow className="my-2 align-items-center">
        <CCol xs="auto">
          <CLabel htmlFor={fieldUID} className="col-form-label">
            {fieldName}
          </CLabel>
        </CCol>
        <CCol xs="auto">
          <CInput
            type="number"
            id={fieldUID}
            onChange={onChangeHandler}
            onKeyDown={keyDownHandler}
          />
        </CCol>
      </CRow>
      <UnitSelectComponent setUnitHandler={setUnitHandler} {...unit} />
    </>
  );
};

NumberField.propTypes = NumberFieldPropTypes;

export default NumberField;
