import {
  AdminRoutes,
  ChatRoutes,
  CompanyRoutes,
  ErrorPageRoutes,
  LandingPageRoutes,
  LoginRoutes,
  PrivacyRoutes,
  ProfileRoutes,
  RegistrationRoutes,
  TestRoutes,
  ProjectRoutes,
  ForgotPasswordRoutes,
} from "~pages";

// public routes are open to anyone
export const publicRoutes = [
  ...LandingPageRoutes,
  ...ErrorPageRoutes,
  ...PrivacyRoutes,
  ...RegistrationRoutes,
  ...TestRoutes,
  ...ForgotPasswordRoutes,
];
// private routes are only accessible with valid token
export const privateRoutes = [
  ...AdminRoutes,
  ...ChatRoutes,
  ...CompanyRoutes,
  ...ProfileRoutes,
  ...ProjectRoutes,
  ...LoginRoutes,
];
