import * as React from "react";

const AdminCompanyIndex = React.lazy(() => import("./CompanyListPage"));
const AdminCompanyForm = React.lazy(() => import("./CompanyFormPage"));

const routePrefix = "/admin/company";

export const AdminCompanyRoutes = [
  {
    id: "ADMIN_COMPANY",
    exact: true,
    path: `${routePrefix}`,
    layoutId: "MainLayout",
    component: AdminCompanyIndex,
    navigation: {
      section: "admin",
      navGroup: "JSU Management",
      navTitle: "Company",
    },
  },
  {
    id: "ADMIN_COMPANY_CREATE",
    path: `${routePrefix}/create`,
    layoutId: "MainLayout",
    component: AdminCompanyForm,
  },
  {
    id: "ADMIN_COMPANY_UPDATE",
    path: `${routePrefix}/:id`,
    layoutId: "MainLayout",
    component: AdminCompanyForm,
  },
];
