import React, { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import { CCol, CLabel, CRow } from "@coreui/react";

import { DatetimeFieldPropTypes } from "./DatetimeField.types";

const DatetimeField = (props) => {
  const [datetimeValue, setDatetimeValue] = useState(new Date());
  const [datetimeType, setDatetimeType] = useState("datetime");

  const { fieldName, fieldUID, fieldType } = props;

  useEffect(() => {
    setDatetimeType(props.datetimeType);
    const [setDataHandler, i] = props.setData;
    setDataHandler(i, {
      id: fieldUID,
      value: new Date(),
      type: fieldType,
    });
  }, [props.datetimeType]);

  const onChangeHandler = (value) => {
    setDatetimeValue(value);
    const [setDataHandler, i] = props.setData;
    setDataHandler(i, {
      id: fieldUID,
      value: value,
      type: fieldType,
    });
  };

  const DateTimeFieldComponent = () => {
    switch (datetimeType) {
      case "datetime":
        return (
          <DateTimePicker
            value={datetimeValue}
            onChange={onChangeHandler}
          ></DateTimePicker>
        );
      case "date":
        return (
          <DatePicker
            value={datetimeValue}
            onChange={onChangeHandler}
          ></DatePicker>
        );
      case "time":
        return (
          <TimePicker
            value={datetimeValue}
            onChange={onChangeHandler}
          ></TimePicker>
        );
      default:
        console.error("Invalid Datetime Type");
        return <h5>Invalid DateTime</h5>;
    }
  };

  return (
    <CRow className="my-2 align-items-center">
      <CCol xs="auto">
        <CLabel htmlFor={fieldUID} className="col-form-label">
          {fieldName}
        </CLabel>
      </CCol>
      <CCol xs="auto">
        <DateTimeFieldComponent></DateTimeFieldComponent>
      </CCol>
    </CRow>
  );
};

DatetimeField.propTypes = DatetimeFieldPropTypes;

export default DatetimeField;
