import PropTypes from "prop-types";
import React from "react";

export const PageHeaderPropTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  hasSearch: PropTypes.bool,
  onSearch: PropTypes.func,
  hasAdd: PropTypes.bool,
  onAdd: PropTypes.func,
  customLeft: PropTypes.node,
};

export const PageHeaderDefaultProps = {
  title: "",
  children: <></>,
  hasSearch: false,
  onSearch: () => {},
  hasAdd: false,
  onAdd: () => {},
  hasAddIcon: true,
  addBtnText: "Add",
  customLeft: <></>,
};
