import React from "react";
import { CCard, CCardBody, CCardHeader, CCollapse } from "@coreui/react";
import { CardPropTypes, CardDefaultProps } from "./Card.types";
import useStyles from "./Card.jss";

/**
 * A collapsible card component
 */
const Card = (props) => {
  const { title, content, button, collapse } = props;
  const classes = useStyles();
  return (
    <CCard className={classes.regionCard}>
      {title && (
        <CCardHeader className={classes.cardTitle}>{title}</CCardHeader>
      )}
      <CCollapse show={!collapse}>
        <CCardBody>{content}</CCardBody>
      </CCollapse>
      {button}
    </CCard>
  );
};

Card.prototype = CardPropTypes;
Card.defaultProps = CardDefaultProps;
export default Card;
